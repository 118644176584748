import React from 'react'
import { Link } from 'react-router-dom'
import I18n from 'locales'
import { authed } from 'services/auth'
import './index.css'

const NavMenu = () => (
  <nav className="NavMenu">
    {authed() ? (
      <React.Fragment>
        <Link to={I18n.t('routes.search')} className="NavMenu__Link">
          {I18n.t('components.navBar.search')}
        </Link>
      </React.Fragment>
    ) : (
      <React.Fragment />
    )}
  </nav>
)

export default NavMenu
