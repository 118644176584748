import axios from 'axios'
import bodybuilder from 'bodybuilder'
import * as auth from 'services/auth'
import { getAllCategoriesAsArray } from 'utils/category'
import { getTransactionType } from 'utils/getTransactionType'

const API_URL = process.env.REACT_APP_API_URL
const ZONE_BY_ID = `${API_URL}/localizacao/zoneamento/id`
const ZONES = `${API_URL}/localizacao/zoneamento`
const REALTY = `${API_URL}/imoveis/_search`
// const REALTY_V2 = `${API_URL}/imoveis/_search/v2`
const REALTY_PAGINATED = `${API_URL}/imoveis/_search/v2/paginated`
const NEIGHBORHOOD_BY_ID = `${API_URL}/localizacao/buscabairro`
const NEIGHBORHOODS = `${API_URL}/localizacao/buscabairrocidade?cidadeId=`
const REGION_STATISTICS = `${API_URL}/imoveis/_search?filter_path=aggregations`
const EVALUATION_SEARCH_INFOS = `${API_URL}/localizacao/buscainfos`

export default class Search {
  constructor({ host = API_URL, cacheResponses = true } = {}) {
    this.host = host
    this.headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      ...auth.headers,
    }
    this.body = bodybuilder()
  }

  applyVariant(options) {
    const {
      suits,
      rooms,
      minTotalArea,
      maxTotalArea,
      minUsefulArea,
      maxUsefulArea,
      priceRange,
      privateBusiness,
      garages,
      cityId,
      businessType,
    } = options

    if (businessType) {
      this.body.query('range', getTransactionType(businessType), {
        gte: priceRange.minPrice || 1,
        lte: priceRange.maxPrice || Number.MAX_SAFE_INTEGER,
      })
    }

    if (cityId) {
      this.body.query('match', 'city_id', cityId)
    }

    if (privateBusiness) {
      this.body.query('match', 'is_private', privateBusiness)
    }

    if (minTotalArea || maxTotalArea) {
      this.body.query('range', 'total_area', {
        gte: minTotalArea || 0,
        lte: maxTotalArea || Number.MAX_SAFE_INTEGER,
      })
    }

    if (minUsefulArea || maxUsefulArea) {
      this.body.query('range', 'area', {
        gte: minUsefulArea || 0,
        lte: maxUsefulArea || Number.MAX_SAFE_INTEGER,
      })
    }

    if (garages) {
      if (garages.includes('4')) {
        this.body.query('bool', f => {
          f.orQuery('terms', 'features_garage', garages.split(','))
          f.orQuery('range', 'features_garage', { gte: 4 })

          return f
        })
      } else {
        this.body.query('bool', b =>
          b.orQuery('terms', 'features_garage', garages.split(',')),
        )
      }
    }

    if (rooms) {
      if (rooms.includes('4')) {
        this.body.query('bool', f => {
          f.orQuery('terms', 'features_bedroom', rooms.split(','))
          f.orQuery('range', 'features_bedroom', { gte: 4 })
          return f
        })
      } else {
        this.body.query('bool', b =>
          b.orQuery('terms', 'features_bedroom', rooms.split(',')),
        )
      }
    }

    if (suits) {
      if (suits.includes('4')) {
        this.body.query('bool', f => {
          f.orQuery('terms', 'features_suite', suits.split(','))
          f.orQuery('range', 'features_suite', { gte: 4 })

          return f
        })
      } else {
        this.body.query('bool', b =>
          b.orQuery('terms', 'features_suite', suits.split(',')),
        )
      }
    }
  }

  applyCore(options) {
    const { size, category, mapped, fullAddress, id } = options
    if (size !== null) this.body.size(size || 10000)
    if (id) {
      if (Array.isArray(id)) {
        this.body.query('bool', b =>
          b.orQuery('terms', 'link_key', id).queryMinimumShouldMatch(1),
        )
      } else {
        this.body.query('match', 'link_key', id)
      }
    } else {
      if (mapped !== undefined)
        this.body.query('match', 'geo_process_pinned', mapped)
      if (fullAddress) this.body.query('match', 'geo_process_address_status', 1)
      if (category) {
        let categoriesArr = []
        const categories = getAllCategoriesAsArray()
        Object.keys(category).forEach(key => {
          if (category[key]) {
            const index = categories.findIndex(obj => obj.name === key)
            if (
              index !== -1 &&
              categoriesArr.indexOf(categories[index].value) === -1
            ) {
              categoriesArr.push(categories[index].value)
            }
          }
        })
        this.body.query('bool', b =>
          b
            .orQuery('terms', 'sub_category_id', categoriesArr)
            .queryMinimumShouldMatch(1),
        )
      }
    }
  }

  applySource(source) {
    if (source) this.body.rawOption('_source', source)
  }

  applyPolygons(polygons) {
    if (polygons) {
      this.body.query('geo_shape', 'location', {
        shape:
          polygons.length > 1
            ? {
                type: 'multipolygon',
                coordinates: polygons.map(polygonArray => [polygonArray]),
              }
            : {
                type: 'polygon',
                coordinates: polygons,
              },
      })
    }
  }

  applyAggregations(aggregations, aggs, withoutNeighborhood = false) {
    if (aggregations) {
      if (withoutNeighborhood) {
        aggregations.forEach(({ type, field, value }) => {
          this.body.aggregation(type, { field }, value)
        })
      } else {
        this.body.aggregation(
          'terms',
          {
            field: 'neighborhood_name.keyword',
            size: 500,
          },
          'neighborhood',
          agg => {
            aggregations.forEach(({ type, field, value }) => {
              agg.aggregation(type, { field }, value)
            })
            return agg
          },
        )
      }
    }
    if (aggs) {
      this.body.aggregation(aggs.type, aggs.field, aggs.name)
    }
  }

  applyOptionals = amenities => {
    if (amenities && amenities.length > 0) {
      for (let amenity of amenities) {
        this.body.query('match', 'amenities', amenity)
      }
    }
  }

  search(query, options = {}) {
    const {
      polygon,
      aggregations = false,
      source,
      aggs,
      filterPaths,
      noNeighborhood = false,
      optionals,
    } = options
    this.body = bodybuilder()
    this.applyCore(options)
    this.applyVariant(options)
    this.applyPolygons(polygon)
    this.applyOptionals(optionals)
    this.applyAggregations(aggregations, aggs, noNeighborhood)
    this.applySource(source)

    return axios.post(
      `${REALTY}${filterPaths ? '?filter_path=' + filterPaths.toString() : ''}`,
      JSON.stringify(JSON.stringify(this.body.build())),
      { headers: this.headers },
    )
  }

  searchV2(query, options = {}) {
    const {
      polygon,
      aggregations = false,
      source,
      aggs,
      filterPaths,
      noNeighborhood = false,
      optionals,
    } = options
    this.body = bodybuilder()
    this.applyCore(options)
    this.applyVariant(options)
    this.applyPolygons(polygon)
    this.applyOptionals(optionals)
    this.applyAggregations(aggregations, aggs, noNeighborhood)
    this.applySource(source)

    return axios.post(
      `${REALTY}${filterPaths ? '?filter_path=' + filterPaths.toString() : ''}`,
      JSON.stringify(JSON.stringify(this.body.build())),
      { headers: this.headers },
    )
  }

  searchPaginated(options = {}) {
    const {
      polygon,
      aggregations = false,
      source,
      aggs,
      filterPaths,
      noNeighborhood = false,
      sort,
      searchAfter,
      query,
      id,
      businessType,
    } = options

    this.body = bodybuilder()
    this.applySource(source)
    this.applyCore(options)
    this.applyAggregations(aggregations, aggs, noNeighborhood)

    if (!id) {
      this.applyVariant(options)
      this.applyPolygons(polygon)
    }

    if (searchAfter) {
      this.body.rawOption('search_after', searchAfter)
    }

    if (sort) {
      for (const sortColumn in sort) {
        if (sort.hasOwnProperty(sortColumn)) {
          const sortType = sort[sortColumn]
          this.body.sort(sortColumn, sortType)
        }
      }
    }

    const transactionType = getTransactionType(businessType)

    if (query) {
      if (Number(query) <= Number.MAX_SAFE_INTEGER) {
        this.body.query('query_string', {
          query,
          fields: [
            'processed_address_formatted',
            transactionType,
            'features_bedroom',
            'total_area',
            'area',
            'features_garage',
          ],
        })
      } else {
        this.body.query('query_string', {
          query: `*${query}*`,
          default_operator: 'AND',
          analyze_wildcard: true,
          fields: [
            'link',
            'processed_address_formatted',
            'neighborhood_name',
            'processed_address_building_name',
            'broker_name',
          ],
        })
      }
    }

    return axios.post(
      `${REALTY_PAGINATED}${
        filterPaths ? '?filter_path=' + filterPaths.toString() : ''
      }`,
      JSON.stringify(JSON.stringify(this.body.build())),
      { headers: this.headers },
    )
  }

  neighborhoods(id) {
    return axios.get(`${NEIGHBORHOODS}${id}`, { headers: this.headers })
  }

  neighborhoodById(id) {
    return axios.get(`${NEIGHBORHOOD_BY_ID}/${id}`, { headers: this.headers })
  }

  // TODO Check this
  regionStatistics(realty) {
    this.body = bodybuilder()
    const businessType = getTransactionType(realty.businessType)

    this.body
      .size(10000)
      .query('match', 'sub_category_id', realty.CategoryId)
      .query('match', 'city_id', realty.CityId)
      .query('range', businessType, { gte: 1 })
      .query('range', 'total_area', { gte: 1 })

    if (realty.Bedrooms) {
      this.body.query('bool', b =>
        b.orQuery('terms', 'features_bedroom', [realty.Bedrooms]),
      )
    }

    if (realty.Garages) {
      this.body.query('bool', b =>
        b.orQuery('terms', 'features_garage', [realty.Garages]),
      )
    }

    if (realty.NeighborhoodId) {
      this.body.query('match', 'neighborhood_id', realty.NeighborhoodId)
    }

    this.body.aggregation(
      'terms',
      {
        field: 'neighborhood_name.keyword',
        size: 500,
      },
      'neighborhood',
      agg => {
        agg.aggregation('percentiles', { field: businessType }, 'price')
        agg.aggregation('percentiles', { field: 'area' }, 'area')
        agg.aggregation('percentiles', { field: 'total_area' }, 'totalArea')
        return agg
      },
    )
    return axios.post(
      REGION_STATISTICS,
      JSON.stringify(JSON.stringify(this.body.build())),
      { headers: this.headers },
    )
  }

  zoneById(id) {
    return axios.get(`${ZONE_BY_ID}/${id}`, { headers: this.headers })
  }

  zones(id) {
    return axios.get(`${ZONES}/${id}`, { headers: this.headers })
  }

  realtyById({ id, source }) {
    this.body = bodybuilder()
    this.applyCore({ id, source })
    return axios.post(
      REALTY,
      JSON.stringify(JSON.stringify(this.body.build())),
      { headers: this.headers },
    )
  }

  evaluationSearchInfos(lat, lng, cityId) {
    return axios.get(`${EVALUATION_SEARCH_INFOS}/${lat}/${lng}/${cityId}`, {
      headers: this.headers,
    })
  }
}
