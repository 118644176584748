import React, { Component } from 'react'
import { Table, Row, Col } from 'components/AlertTable/styles'
import { Button } from 'components/Buttons'
import classNames from 'classnames'
import I18n from 'locales'
import moment from 'moment'
import { downloadRealtyReport } from 'services/report'
import { FadeLoader } from 'react-spinners'
import { COLORS } from 'constants/colors'
import { debounce } from 'lodash'
import ReportModal from 'components/ReportModal'
import { RealtyConsumer } from 'context/Realty'
import { sortEnum } from 'constants/sortEnum'
import './index.css'

const WIDTH = `${98 / 13}%`
const PAGE_SIZE = 30

class ShowRealtyPaginated extends Component {
  constructor(props, context) {
    super(props, context)
    const { businessType } = props
    this.WIDTH = context.defaultColumnWidth || WIDTH
    this.forward = true
    this.idList =
      (context.idList && context.idList.length > 0 && context.idList) || null
    this.sortObject = {
      [sortEnum(businessType).AdDate]: 'desc',
      _id: 'asc',
    }

    this.state = {
      realties: [],
      totalRealties: 1,
      notFound: false,
      sortAdDate: 'desc',
      sortBedrooms: null,
      sortGarages: null,
      sortPrice: null,
      sortTotalArea: null,
      sortUsefulArea: null,
      reportModalVisible: false,
      selectedItems: [],
      page: 0,
      query: '',
      searching: false,
    }
  }

  componentDidMount() {
    this.handleLoadMessage()
    this.context.alert && this.setRealties(this.forward)
  }

  componentDidUpdate() {
    const { idList, alert } = this.context
    if (
      idList &&
      idList.length > 0 &&
      JSON.stringify(idList) !== JSON.stringify(this.idList)
    ) {
      this.idList = idList
    }
    !alert && this.shouldUpdateRealties() && this.setRealties(this.forward)
  }

  shouldUpdateRealties = () =>
    JSON.stringify(this.context.realties) !==
    JSON.stringify(this.state.realties)

  handlePageChange = forward => {
    this.setState({ loading: true }, () => {
      const { handlePageChange, mappedClick } = this.context
      const { query } = this.state
      let sortObj = { ...this.sortObject }

      this.forward = forward
      const searchAfter = this.searchAfter[(this.forward && 1) || 0]

      if (!this.forward) {
        Object.keys(sortObj).forEach(key => {
          switch (sortObj[key]) {
            case 'asc':
              sortObj[key] = 'desc'
              break
            default:
              sortObj[key] = 'asc'
              break
          }
        })
      }

      handlePageChange(
        {
          id: this.idList,
          sort: sortObj,
          mapped: mappedClick,
          query,
          searchAfter,
        },
        this.setLoadingFalse,
      )

      this.setState(state => ({
        ...state,
        page: state.page + ((this.forward === 'forward' && 1) || -1),
      }))
    })
  }

  setLoadingFalse = () => this.setState({ loading: false })

  setRealties = forward => {
    const { totalRealties, realties } = this.context
    if (totalRealties > 0) {
      this.setState(
        state => ({
          ...state,
          totalRealties: Math.ceil(totalRealties / PAGE_SIZE),
          loadMessage: null,
          realties: forward ? realties : realties.reverse(),
        }),
        () => {
          const { realties } = this.state
          const lastIndex = realties.length - 1 >= 0 ? realties.length - 1 : 0
          this.searchAfter = [realties[0].sort, realties[lastIndex].sort]
        },
      )
    } else {
      this.setState(
        {
          totalRealties: 0,
          loadMessage: null,
          realties: [],
          notFound: true,
        },
        () => {
          this.searchAfter = null
        },
      )
    }
  }

  selectPage = (unselect = false) => {
    const { selectRealty = null } = this.context
    const { realties } = this.state
    let { selectedItems } = this.state
    selectRealty && selectRealty(null, realties)
    if (unselect) {
      selectRealty && selectRealty(null, [])
      realties.forEach(realty => {
        const index = selectedItems.findIndex(item => item.Id === realty.Id)
        if (index !== -1) selectedItems.splice(index, 1)
      })
    } else {
      Array.prototype.push.apply(selectedItems, realties)
    }
    this.setState(state => ({ ...state, selectedItems }))
  }

  handleLoadMessage = () => {
    setTimeout(
      () =>
        this.setState({
          loadMessage: I18n.t('showRealty.loadMessage'),
          timeOutMessage: I18n.t('showRealty.timeOutMessage'),
        }),
      1500,
    )
  }

  sortBy = column => {
    this.setState(
      {
        loading: true,
        page: 0,
        sortAdDate: null,
        sortBedrooms: null,
        sortGarages: null,
        sortPrice: null,
        sortTotalArea: null,
        sortUsefulArea: null,
        [`sort${column}`]:
          this.state[`sort${column}`] === 'asc' ? 'desc' : 'asc',
      },
      () => {
        const { handlePageChange, mappedClick } = this.context
        const { query } = this.state
        const { businessType } = this.props
        this.sortObject = {
          [sortEnum(businessType)[column]]: this.state[`sort${column}`],
          _id: 'asc',
        }
        handlePageChange(
          {
            id: this.idList,
            sort: this.sortObject,
            mapped: mappedClick,
            query,
          },
          this.setLoadingFalse,
        )
      },
    )
  }

  isPageSelected = () => {
    const { realties, selectedItems } = this.state
    if (realties.length > 0) {
      for (let realty of realties) {
        if (!selectedItems.find(item => item.Id === realty.Id)) return false
      }
      return true
    }
    return false
  }

  renderHeader = () => {
    const selected = this.isPageSelected()
    const { selectable = true, showActions = true } = this.context
    const icon = selected ? 'check_box' : 'check_box_outline_blank'
    return (
      <Row header>
        {selectable && (
          <Col textCenter header width="2%" className-="ShowRealty__Sortable">
            <div
              className="ShowRealty__Selection"
              onClick={() => this.selectPage(selected)}
            >
              <i className="material-icons">{icon}</i>
            </div>
          </Col>
        )}
        {this.renderHeaderCol(null, I18n.t('alert.realState'))}
        {this.renderHeaderCol(null, I18n.t('alert.neighborhood'))}
        {this.renderHeaderCol(null, I18n.t('alert.building'))}
        {this.renderHeaderCol('Price', I18n.t('alert.value'))}
        {this.renderHeaderCol('Bedrooms', I18n.t('alert.room'))}
        {this.renderHeaderCol('Garages', I18n.t('alert.garageMin'))}
        {this.renderHeaderCol('TotalArea', I18n.t('alert.totalArea'))}
        {this.renderHeaderCol(null, I18n.t('alert.totalAreaValue'))}
        {this.renderHeaderCol('UsefulArea', I18n.t('alert.usefulArea'))}
        {this.renderHeaderCol(null, I18n.t('alert.userfulAreaValue'))}
        {this.renderHeaderCol(null, I18n.t('alert.address'))}
        {this.renderHeaderCol('AdDate', I18n.t('alert.inclusion'))}
        {showActions && (
          <Col textCenter header width={this.WIDTH}>
            {I18n.t('alert.actions')}
          </Col>
        )}
      </Row>
    )
  }

  searchRealty = query => {
    this.setState(
      {
        loading: true,
        page: 0,
        query,
      },
      () => {
        const { handlePageChange, mappedClick } = this.context
        if (!this.state.searching) {
          this.setState({ searching: true })
          handlePageChange(
            {
              id: this.idList,
              sort: this.sortObject,
              mapped: mappedClick,
              query,
            },
            () => {
              this.setLoadingFalse()
              this.setState({ searching: false })
            },
          )
        }
      },
    )
  }

  debouncedSearchRealty = debounce(this.searchRealty, 400)

  handleSearchRealty = query => {
    this.debouncedSearchRealty(query)
  }

  debouncedSearchRealty = debounce(this.searchRealty, 350)

  handleSearchRealty = value => this.debouncedSearchRealty(value)

  handleSelect = realty => {
    const { selectRealty = null } = this.context
    if (selectRealty) {
      selectRealty(realty)
    }

    let { selectedItems } = this.state
    let index = selectedItems.findIndex(item => item.Id === realty.Id)

    if (index === -1) selectedItems.push(realty)
    else selectedItems.splice(index, 1)

    this.setState(state => ({ ...state, selectedItems }))
  }

  exportReport = format => {
    const { selectedItems } = this.state
    const { businessType } = this.props
    const ids = selectedItems.map(({ Id }) => Id)

    this.setState({ loading: true })
    //TODO this req probably needs businesstype
    downloadRealtyReport({ ids, format, businessType })
      .then(({ request, data: { Message } }) => {
        this.setState({ loading: false, selectedItems: [] })
        request.onloadend = function(event) {
          if (request.status === 200) {
            let link = document.createElement('a')
            document.body.appendChild(link)
            link.download = `Relatorio.${format}`
            link.href = window.URL.createObjectURL(request.response)
            link.click()
          } else {
            window.M.toast({
              classes: 'Toast--failure',
              html: Message,
            })
          }
        }
      })
      .catch(err => {
        console.error(err)
        const errorClass = 'Toast--failure'
        let message = I18n.t('common.fail')
        if (err.response.status === 401) {
          message = 'Usuário Trial não pode efetuar download.'
        }
        window.M.toast({
          classes: errorClass,
          html: message,
        })
        this.setState({ loading: false })
      })
  }

  renderCol = (title, label, center = true) => (
    <Col title={title} label={label} textCenter={center} width={this.WIDTH}>
      {title}
    </Col>
  )

  renderHeaderCol = (sort, title, center = true) => {
    const { loading } = this.state
    const upArrow =
      sort === 'AdDate'
        ? this.state[`sort${sort}`] === 'desc'
        : this.state[`sort${sort}`] === 'asc'
    const classes = classNames({
      'ShowRealty--sortable': sort,
      'ShowRealty--loading': loading,
    })

    const icon = classNames({
      keyboard_arrow_up: upArrow,
      keyboard_arrow_down: this.state[`sort${sort}`] && !upArrow,
      sort: !this.state[`sort${sort}`],
    })
    return (
      <Col
        textCenter={center}
        header
        title={title}
        width={this.WIDTH}
        onClick={() => sort && !loading && this.sortBy(sort)}
        className={classes}
      >
        <span>{title}</span> {sort && <i className="material-icons">{icon}</i>}
      </Col>
    )
  }

  openReportModal = id => {
    this.setState({ reportRealtyId: id, reportModalVisible: true })
  }

  renderRow = (realty, index) => {
    const { businessType } = this.props
    const now = moment(new Date())
    const realState = realty.RealState || I18n.t('common.notInformed')
    const neighborhood = realty.Neighborhood || I18n.t('common.notInformed')
    const building = realty.Building || I18n.t('common.notInformed')
    const rooms =
      realty.Bedrooms >= 0 ? realty.Bedrooms : I18n.t('common.notInformed')
    const garages =
      realty.Garages >= 0 ? realty.Garages : I18n.t('common.notInformed')
    const totalArea = realty.TotalArea || I18n.t('common.notInformed')
    const meterPrice = realty.MeterPrice
    const usefulArea = realty.UsefulArea || I18n.t('common.notInformed')
    const address = realty.Address || I18n.t('common.notInformed')
    const price = realty.Price || I18n.t('common.notInformed')
    const usefulMeterPrice =
      realty.UsefulMeterPrice || I18n.t('common.notInformed')
    const duration = moment
      .duration(now.diff(realty.AdDate))
      .asDays()
      .toFixed(0)
    const adDate = realty.AdDate
      ? `${duration} dia(s)`
      : I18n.t('common.notInformed')
    const selected = this.context.selectRealty
      ? this.context.selectedRealty.find(item => item.Id === realty.Id)
      : this.state.selectedItems.find(item => item.Id === realty.Id)
    const { selectable = true, showActions = true } = this.context
    const icon = selected ? 'check_box' : 'check_box_outline_blank'
    const link = realty.Link.includes('zapimoveis')
      ? `${realty.Link}?${new Date()
          .toDateString()
          .split(' ')
          .join('-')}`
      : realty.Link

    return (
      <Row className="ShowRealty__Row" key={index}>
        {selectable && (
          <Col textCenter width="2%">
            <div
              onClick={() => this.handleSelect(realty)}
              className="ShowRealty__Selection"
            >
              <i className="material-icons">{icon}</i>
            </div>
          </Col>
        )}
        {this.renderCol(realState, I18n.t('alert.realState'), false)}
        {this.renderCol(neighborhood, I18n.t('alert.neighborhood'))}
        {this.renderCol(building, I18n.t('alert.building'))}
        {this.renderCol(price, I18n.t('alert.value'))}
        {this.renderCol(rooms, I18n.t('alert.room'))}
        {this.renderCol(garages, I18n.t('alert.garageMin'))}
        {this.renderCol(totalArea, I18n.t('alert.totalArea'))}
        {this.renderCol(meterPrice, I18n.t('alert.totalAreaValue'))}
        {this.renderCol(usefulArea, I18n.t('alert.usefulArea'))}
        {this.renderCol(usefulMeterPrice, I18n.t('alert.userfulAreaValue'))}
        {this.renderCol(address, I18n.t('alert.address'), false)}
        {this.renderCol(adDate, I18n.t('alert.inclusion'))}
        {showActions && (
          <Col
            label={I18n.t('alert.actions')}
            action="1"
            textCenter
            width={this.WIDTH}
          >
            <Row>
              <a
                title="Ver Anúncio"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="ShowRealty__Link ShowRealty__Actions"
              >
                <i className="material-icons">visibility</i>
              </a>
            </Row>
            {!this.context.alert && (
              <React.Fragment>
                <Row>
                  <Button
                    title="Relatório"
                    btnStyle="primary"
                    btnSize="sm"
                    className="ShowRealty__Actions"
                    block
                    onClick={() =>
                      window.open(`/rgi/${realty.Id}/${businessType}`, '_tab')
                    }
                  >
                    <i className="material-icons">list_alt</i>
                  </Button>
                </Row>
                <Row>
                  <Button
                    title="Reportar"
                    btnStyle="primary"
                    btnSize="sm"
                    className="ShowRealty__Actions"
                    onClick={() => this.openReportModal(realty.Id)}
                    block
                  >
                    <i className="material-icons">bug_report</i>
                  </Button>
                </Row>
              </React.Fragment>
            )}
          </Col>
        )}
      </Row>
    )
  }

  toggleReportModal = () => {
    this.setState({
      reportModalVisible: false,
      reportRealtyId: null,
    })
  }

  render() {
    const {
      visible,
      toggle,
      modal = true,
      showClose = true,
      exportable = true,
      searchable = true,
      defaultColumnWidth,
    } = this.context
    const {
      loading,
      page,
      selectedItems,
      loadMessage,
      notFound,
      realties,
      totalRealties,
      reportModalVisible,
      reportRealtyId,
      searching,
    } = this.state
    const classes = classNames({
      ShowRealty: visible,
      'ShowRealty--hidden': !visible,
      'ShowRealty--loading': loading,
    })
    return (
      <div>
        <div
          className={
            modal
              ? classes
              : `ShowRealty__Evaluation${(defaultColumnWidth && '--nomargin') ||
                  ''}`
          }
        >
          <div
            className={modal ? 'ShowRealty__Modal' : 'ShowRealty__NotModal'}
            data-tut="step-ten"
          >
            <div className="ShowRealty__Header">
              {showClose && (
                <i className="material-icons" onClick={toggle}>
                  close
                </i>
              )}
              <div className="ShowRealty__Toolbar">
                {searchable && (
                  <input
                    placeholder={I18n.t('alert.search')}
                    disabled={searching}
                    className={`ShowRealty__SearchInput ${defaultColumnWidth &&
                      'ShowRealty__SearchInput--small'}`}
                    onChange={({ target: { value } }) =>
                      this.handleSearchRealty(value)
                    }
                  />
                )}
                {exportable && (
                  <div>
                    <Button
                      disabled={selectedItems.length === 0}
                      btnStyle="primary"
                      onClick={() => this.exportReport('pdf')}
                    >
                      <i className="material-icons">picture_as_pdf</i>
                      {I18n.t('showRealty.exportPDF')}
                    </Button>
                    <Button
                      disabled={selectedItems.length === 0}
                      btnStyle="primary"
                      onClick={() => this.exportReport('xlsx')}
                    >
                      <i className="material-icons">description</i>
                      {I18n.t('showRealty.exportCSV')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="ShowRealty__Body">
              <Table className="ShowRealty__Table">
                {this.renderHeader()}
                <div className="ShowRealty__ScrollContainer">
                  {realties && realties.length > 0 ? (
                    realties.map((realty, index) =>
                      this.renderRow(realty, index),
                    )
                  ) : loadMessage && !notFound ? (
                    <div className="ShowRealty__Loader">
                      <FadeLoader
                        sizeUnit="px"
                        size={50}
                        color={COLORS.gray28}
                        visible
                      />
                      {loadMessage}
                    </div>
                  ) : (
                    notFound && 'Opa, nenhum imóvel correspondente :('
                  )}
                </div>
              </Table>
            </div>
            <div className="ShowRealty__Footer">
              {page > 0 && (
                <i
                  className={`material-icons ${(loading && 'cursor-wait') ||
                    ''}`}
                  onClick={() => {
                    !loading && this.handlePageChange()
                  }}
                >
                  keyboard_arrow_left
                </i>
              )}
              {`${page + 1} / ${totalRealties}`}
              {page < totalRealties - 1 && (
                <i
                  className={`material-icons ${(loading && 'cursor-wait') ||
                    ''}`}
                  onClick={() => {
                    !loading && this.handlePageChange('forward')
                  }}
                >
                  keyboard_arrow_right
                </i>
              )}
            </div>
          </div>
        </div>
        <ReportModal
          id={reportRealtyId}
          visible={reportModalVisible}
          toggle={this.toggleReportModal}
        />
      </div>
    )
  }
}

ShowRealtyPaginated.contextType = RealtyConsumer
export default ShowRealtyPaginated
