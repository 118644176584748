import React from 'react'

const ArrowRight = ({ width = 20, height = 13 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 15"
    version="1.1"
    style={{ marginLeft: 8, display: 'inline-block', verticalAlign: 'middle' }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-786.000000, -1451.000000)"
        fill="#B28B3D"
        fillRule="nonzero"
      >
        <g transform="translate(702.000000, 1445.000000)">
          <polygon
            transform="translate(95.227068, 13.705048) rotate(90.000000) translate(-95.227068, -13.705048) "
            points="95.2192587 3 88 10.2617922 88.6116642 10.8734564 94.7944909 4.69062978 94.7944909 24.4100964 95.6596454 24.4100964 95.6596454 4.69062978 101.842472 10.8734564 102.454136 10.2617922"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default ArrowRight
