export const formatDweller = dweller => {
  const { enderecos } = dweller
  const mainAddress = enderecos[0]
  const address = [
    mainAddress.logradouro,
    mainAddress.numero,
    mainAddress.bairro,
    mainAddress.cidade,
    mainAddress.complemento,
  ].join(', ')
  const type =
    dweller.tipoPessoa &&
    dweller.tipoPessoa.replace(/pessoa|Pessoa/g, '').trim()
  return {
    addresses:
      dweller.enderecos &&
      dweller.enderecos.map(item => ({
        street: item.logradouro,
        number: item.numero,
        cep: item.cep,
        city: item.cidade,
        uf: item.uf,
        neighborhood: item.bairro,
        complement: item.complemento,
      })),
    telephones:
      dweller.telefones &&
      dweller.telefones.map(item => ({
        number: item.telefone,
        provider: item.operadora,
        relation: item.relacao,
        name: item.nome,
      })),
    emails: dweller.emails.map(item => ({ email: item.toLowerCase().trim() })),
    personInfo: dweller.dadosPf && {
      cbo: dweller.dadosPf.cbo,
      socialClass: dweller.dadosPf.classeSocial,
      birthDate: dweller.dadosPf.dataNascimento,
      entrepreneur: (dweller.dadosPf.empresario && 'Sim') || 'Não',
      scholarship: dweller.dadosPf.grauInstrucao,
      age: dweller.dadosPf.idade,
      name: dweller.dadosPf.nome,
      mothersName: dweller.dadosPf.nomeMae,
      death: dweller.dadosPf.obito,
      income: dweller.dadosPf.renda,
    },
    companyInfo: dweller.dadosPj && {
      cnae: dweller.dadosPj.cnae,
      cnaeDescription: dweller.dadosPj.cnaeDescricao,
      openingDate: dweller.dadosPj.dataAbertura,
      income: dweller.dadosPj.faturamento,
      legalNature: dweller.dadosPj.naturezaJuridica,
      fantasyName: dweller.dadosPj.nomeFantasia,
      companySize: dweller.dadosPj.porte,
      workers: dweller.dadosPj.qtdeFuncionarios,
      companyName: dweller.dadosPj.razaoSocial,
    },
    document: dweller.documento,
    companyData:
      dweller.dadosEmpresariais &&
      dweller.dadosEmpresariais.length > 0 &&
      dweller.dadosEmpresariais.map(item => ({
        ocupation: item.cargoSocio,
        joinDate: item.dataEntrada,
        document: item.documento,
        name: item.nome,
        participation: item.participacao,
      })),
    address: address.substring(0, address.length - 2),
    main_uf: mainAddress.uf,
    city: mainAddress.cidade,
    neighborhood: mainAddress.bairro,
    infoStatus: dweller.statusInformacao,
    type,
  }
}

export const formatOwner = dweller => {
  const Type =
    (dweller.tipo_documento &&
      (dweller.tipo_documento.includes('PJ')
        ? 'Pessoa Jurídica'
        : 'Pessoa Física')) ||
    ''

  return {
    addresses: [
      {
        raw: dweller.endereco,
        street: dweller.logradouro,
        number: dweller.numero,
        city: dweller.cidade.Nome,
        uf: dweller.cidade.UF,
        complement: dweller.complemento,
      },
    ],
    Complement: dweller.complemento,
    Name: dweller.proprietario,
    document: dweller.numero_documento,
    FullAddress: `${dweller.logradouro}, ${dweller.numero}`,
    Type,
  }
}
