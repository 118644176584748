import React from 'react'
import classNames from 'classnames'
import { Grid, Row, Col } from 'components/Grid'
import { Link } from 'react-router-dom'
import Logo from 'components/Logo'
import NavMenu from 'components/NavMenu'
import './index.css'

const HeaderLoggedOut = ({ home, transparent }) => {
  const classes = classNames('HeaderLoggedIn', 'container-fluid', {
    Header: !transparent,
    'Header--transparent': transparent,
    'Header--home': home,
  })

  return (
    <header className={classes}>
      <Grid>
        <Row>
          <Col xs={2}>
            <Logo className="Header__Logo" title="EEmovel" />
          </Col>
          <Col xs={10}>
            <div className="Header__Hide">
              <Row end="xs">
                <Col>
                  <NavMenu />
                </Col>
                <Col>
                  <Link className="Header__Link" to="/login">
                    Login
                  </Link>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Grid>
    </header>
  )
}

export default HeaderLoggedOut
