import React, { Component } from 'react'
import classNames from 'classnames'
import I18n from 'locales'
import { deburr } from 'lodash'
import { Form, Field, Checkbox, createFilter, Formik } from 'components/Form'
import { Col, Row } from 'components/Grid'
import { Button } from 'components/Buttons'
import ArrowSvgIcon from 'components/SvgIcons/ArrowSvgIcon'
import { SearchConsumer } from 'context/Search'
import { verifyBeforeSubmit } from './verifierHelper'
import './index.css'

class FilterMore extends Component {
  constructor(props, context) {
    super()
    const {
      filters: {
        more: { building, realState, reference, optionals },
      },
    } = context
    this.state = {
      optionalsFilter: [],
      isMoreDetailsOpen: building || realState || reference,
      isOptionalsOpen: optionals.length > 0,
      active: false,
    }
  }

  toggleContainer = () => {
    this.setState(state => ({ active: !state.active }))
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  componentDidMount() {
    const { optionalsFilter } = this.context
    this.setOptionals(optionalsFilter)
  }

  setOptionals = optionals => {
    this.setState({ optionalsFilter: [...optionals] })
  }

  searchOptionals = ({ target: { value } }) => {
    if (value) {
      this.setState({
        optionalsFilter: this.context.optionalsFilter.filter(({ Opcional }) =>
          deburr(Opcional.toLowerCase()).includes(deburr(value.toLowerCase())),
        ),
      })
    } else {
      this.setState({
        optionalsFilter: this.context.optionalsFilter,
      })
    }
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return
    }

    this.state.active && this.submit && this.submit()
  }

  getCount() {
    const { more } = this.context.filters
    let count = more.optionals.length

    for (let key of Object.keys(more)) {
      if (more[key] && key !== 'optionals') {
        count++
      }
    }

    return count
  }

  selectItem = (name, { setFieldValue }, values, value) => {
    const arrayValues = values[name].split(',')
    const index = arrayValues.indexOf(value)

    if (index === -1) arrayValues.push(value)
    else arrayValues.splice(index, 1)

    setFieldValue(
      name,
      arrayValues
        .filter(value => value !== '')
        .sort()
        .toString(),
    )
  }

  renderMicroOption = (name, label, formik, values, noZero = false) => {
    const baseClass = 'FilterMore__OptionButton'
    const zeroClasses = classNames(baseClass, {
      'FilterMore__OptionButton--active': values[name].includes('0'),
    })
    const oneClasses = classNames(baseClass, {
      'FilterMore__OptionButton--active': values[name].includes('1'),
    })
    const twoClasses = classNames(baseClass, {
      'FilterMore__OptionButton--active': values[name].includes('2'),
    })
    const threeClasses = classNames(baseClass, {
      'FilterMore__OptionButton--active': values[name].includes('3'),
    })
    const fourClasses = classNames(baseClass, {
      'FilterMore__OptionButton--active': values[name].includes('4'),
    })
    return (
      <React.Fragment>
        <Row className="FilterMore__Row">
          <Col xs={12} className="FilterMore__MicroOption__Label">
            <span className="FilterMore__MicroOption">{label}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="FilterMore__ButtonContainer">
            {!noZero && (
              <div
                className={zeroClasses}
                onClick={() => this.selectItem(name, formik, values, '0')}
              >
                0
              </div>
            )}
            <div
              className={oneClasses}
              onClick={() => this.selectItem(name, formik, values, '1')}
            >
              1
            </div>
            <div
              className={twoClasses}
              onClick={() => this.selectItem(name, formik, values, '2')}
            >
              2
            </div>
            <div
              className={threeClasses}
              onClick={() => this.selectItem(name, formik, values, '3')}
            >
              3
            </div>
            <div
              className={fourClasses}
              onClick={() => this.selectItem(name, formik, values, '4')}
            >
              4+
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderMacroOption = label => (
    <Row>
      <span className="FilterMore__MacroOption">{label}</span>
    </Row>
  )

  renderField = (label, placeholder, name, values, formik) => (
    <Row>
      <Col xs className="FilterMore__CustomPadFieldColumn">
        <Field
          label={label}
          placeholder={placeholder}
          name={name}
          type="number"
          value={values[name]}
          onChange={({ target: { name, value } }) =>
            formik.setFieldValue(name, Number(value))
          }
        />
      </Col>
    </Row>
  )

  renderAreaField = (names, values, props) => (
    <div>
      <Row between="xs" className="FilterMoreMobile__Row">
        <Col xs={6} className="FilterMoreMobile__CustomPadColumn">
          <Field
            label={I18n.t('common.from')}
            name={names[0]}
            value={values[names[0]]}
            type="number"
          />
        </Col>
        <Col xs={6} className="FilterMore__CustomPadColumn">
          <Field
            label={I18n.t('common.to')}
            name={names[1]}
            value={values[names[1]]}
            type="number"
          />
        </Col>
      </Row>
    </div>
  )

  render() {
    const { className } = this.props
    const classes = classNames('SearchFilter', 'FilterMore', className)
    const { isOptionalsOpen, active } = this.state
    return (
      <SearchConsumer>
        {({ onSubmit, filters: { more }, optionalsFilter }) => (
          <div style={{ position: 'relative', zIndex: 2 }}>
            <div className={classes} data-tut="step-one">
              {createFilter({
                label: I18n.t('components.filters.moreFilter'),
                count: this.getCount(),
                icon: { size: 20 },
                isOpen: this.state.active,
                onClick: this.toggleContainer,
              })}
              {active && (
                <div className="FilterContainer">
                  <Formik
                    onSubmit={values => verifyBeforeSubmit(values, onSubmit)}
                    initialValues={more}
                  >
                    {({ values, ...props }) => {
                      this.submit = props.handleSubmit
                      return (
                        <Form>
                          <div ref={el => (this.node = el)}>
                            <Row between="xs">
                              <span className="FilterMore__Instructions">
                                {I18n.t('components.filters.moreInstructions')}
                              </span>
                            </Row>
                            <div className="FilterMore__OptionContainer">
                              <Row start="xs">
                                <Col xs={12}>
                                  <Checkbox
                                    name="fullAddress"
                                    label={I18n.t(
                                      'components.filters.fullAddress',
                                    )}
                                    checked={values.fullAddress}
                                    onChange={props.handleChange}
                                  />
                                </Col>
                              </Row>
                              {this.renderMacroOption(
                                I18n.t('components.filters.rooms'),
                              )}
                              {this.renderMicroOption(
                                'rooms',
                                I18n.t('components.filters.room'),
                                props,
                                values,
                                true,
                              )}
                              {this.renderMicroOption(
                                'suits',
                                I18n.t('components.filters.suits'),
                                props,
                                values,
                              )}
                              {this.renderMicroOption(
                                'garages',
                                I18n.t('components.filters.garages'),
                                props,
                                values,
                              )}
                              {this.renderMacroOption(
                                I18n.t('components.filters.propertyUsefulArea'),
                              )}
                              {this.renderAreaField(
                                ['minUsefulArea', 'maxUsefulArea'],
                                values,
                                props,
                              )}
                              {this.renderMacroOption(
                                I18n.t('components.filters.propertyTotalArea'),
                              )}
                              {this.renderAreaField(
                                ['minTotalArea', 'maxTotalArea'],
                                values,
                                props,
                              )}
                              <Row
                                className="FilterMore__MacroOption FilterMore__MoreDetails"
                                style={{
                                  paddingBottom: isOptionalsOpen ? '8px' : '0',
                                }}
                                onClick={() =>
                                  this.setState({
                                    isOptionalsOpen: !isOptionalsOpen,
                                  })
                                }
                              >
                                {I18n.t('components.filters.optionals')}
                                <ArrowSvgIcon
                                  className="FilterMore__ArrowIcon"
                                  up={isOptionalsOpen}
                                  width={20}
                                  height={20}
                                />
                              </Row>
                              {isOptionalsOpen && optionalsFilter.length > 0 && (
                                <Row className="FilterMore__Search">
                                  <Col xs={12}>
                                    <input
                                      className="FormField__Input"
                                      onChange={this.searchOptionals}
                                      placeholder="Buscar Opcional"
                                    />
                                  </Col>
                                </Row>
                              )}
                              {isOptionalsOpen &&
                              this.state.optionalsFilter.length
                                ? this.state.optionalsFilter.map(
                                    ({ Opcional }) => (
                                      <Row
                                        middle="xs"
                                        key={Opcional}
                                        className="FilterMore__MoreDetailsRow"
                                      >
                                        <Col>
                                          <Checkbox
                                            name={Opcional}
                                            label={Opcional}
                                            checked={values.optionals.includes(
                                              Opcional,
                                            )}
                                            onChange={({
                                              target: { checked },
                                            }) => {
                                              let optionalss = values.optionals
                                              const index = optionalss.indexOf(
                                                Opcional,
                                              )

                                              if (index === -1)
                                                optionalss.push(Opcional)
                                              else optionalss.splice(index, 1)

                                              props.setFieldValue(
                                                'optionals',
                                                optionalss,
                                              )
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    ),
                                  )
                                : isOptionalsOpen &&
                                  optionalsFilter.length === 0
                                ? 'É necessário selecionar algum tipo de imóvel para ter opcionais'
                                : isOptionalsOpen && 'Nada encontrado'}
                            </div>
                            <Row between="xs">
                              <Col>
                                <Button
                                  className="FilterMore__Popover__Cancel"
                                  btnStyle="link"
                                  onClick={this.toggleContainer}
                                >
                                  {I18n.t('components.actions.cancel')}
                                </Button>
                              </Col>
                              <Col>
                                <Button btnStyle="link" type="submit">
                                  {I18n.t('components.actions.ok')}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        )}
      </SearchConsumer>
    )
  }
}
FilterMore.contextType = SearchConsumer
export default FilterMore
