import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { logout, userInfo } from 'services/auth'
import { getAlertsNotReaded } from 'services/alert'
import I18n from 'locales'
import './index.css'

class UserMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
    this.user = userInfo()
    getAlertsNotReaded().then(({ data }) => {
      this.alertsNotReaded = data
      this.forceUpdate()
    })
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = e => {
    if (
      (this.node && this.node.contains(e.target)) ||
      (this.secondNode && this.secondNode.contains(e.target))
    ) {
      return
    }

    this.setState({ isOpen: false })
  }

  render() {
    const { username } = this.user
    const { isOpen } = this.state
    return (
      <div className="UserMenu__Container" ref={el => (this.secondNode = el)}>
        <div className="UserMenu">
          <div className="UserMenu__TargetContainer">
            <div
              className="UserMenu__TargetContainer"
              onClick={() => this.setState({ isOpen: !isOpen })}
            >
              <span className="UserMenu__UserBtn">
                {username.split(' ')[0] || 'John Doe'}
              </span>
              <div>
                <i className="material-icons tiny UserMenu__Arrow">
                  {`keyboard_arrow_${isOpen ? 'up' : 'down'}`}
                </i>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <div
            className="UserMenu__ItemsContainer"
            ref={el => (this.node = el)}
          >
            <Link
              onClick={logout}
              to="/"
              className="UserMenu__Item UserMenu__Item__Exit"
            >
              {I18n.t('components.actions.exit')}
            </Link>
          </div>
        )}
      </div>
    )
  }
}

export default UserMenu
