//leave no comments here
module.exports = {
  2878: {
    name: 'Curitiba',
    state: 'PR',
    coordinates: [-25.424429, -49.265382],
    zoom: 12,
    capital: true,
    zoning: true,
  },
  3164: {
    name: 'Toledo',
    state: 'PR',
    coordinates: [-24.7307669, -53.7566772],
    zoom: 13,
    zoning: true,
  },
  2853: {
    name: 'Cascavel',
    state: 'PR',
    coordinates: [-24.955278, -53.464267],
    zoom: 13,
    zoning: true,
  },
  2976: {
    name: 'Londrina',
    state: 'PR',
    coordinates: [-23.313407, -51.169489],
    zoom: 13,
    zoning: true,
  },
  10000: {
    name: 'Maringá 2',
    state: 'PR',
    coordinates: [-23.420988, -51.933054],
    zoom: 13,
  },
  2994: {
    name: 'Maringá',
    state: 'PR',
    coordinates: [-23.420988, -51.933054],
    zoom: 13,
    zoning: true,
  },
  4160: {
    name: 'Pelotas',
    state: 'RS',
    coordinates: [-31.746817, -52.336373],
    zoom: 11,
  },
  3945: {
    name: 'Caxias do Sul',
    state: 'RS',
    coordinates: [-29.163105, -51.179873],
    zoom: 13,
  },
  5270: {
    name: 'São Paulo',
    state: 'SP',
    coordinates: [-23.550421, -46.633468],
    capital: true,
    owners: true,
    zoom: 12,
  },
  4549: {
    name: 'Joinville',
    state: 'SC',
    coordinates: [-26.304387, -48.846368],
    zoom: 12,
    zoning: 4549,
  },
  4174: {
    name: 'Porto Alegre',
    state: 'RS',
    coordinates: [-30.087889, -51.186142],
    zoom: 12,
    zoning: true,
    capital: true,
  },
  4500: {
    name: 'Florianópolis',
    state: 'SC',
    coordinates: [-27.601499, -48.48737],
    zoom: 11,
    zoning: true,
    capital: true,
  },
  4449: {
    name: 'Blumenau',
    state: 'SC',
    coordinates: [-26.916585, -49.071727],
    zoom: 12,
    zoning: true,
  },
  4440: {
    name: 'Balneário Camboriú',
    state: 'SC',
    coordinates: [-26.988319, -48.63255],
    zoom: 13,
    zoning: true,
    owners: true,
  },
  3658: {
    name: 'Rio de Janeiro',
    state: 'RJ',
    coordinates: [-22.927887, -43.223489],
    zoom: 11,
    zoning: true,
  },
  1630: {
    name: 'Belo Horizonte',
    state: 'MG',
    coordinates: [-19.894897, -43.948649],
    capital: true,
    zoom: 12,
    owners: true,
  },
  882: {
    name: 'Brasília',
    state: 'DF',
    coordinates: [-15.791921, -47.888817],
    zoom: 11,
    capital: true,
    zoning: true,
  },
  616: {
    name: 'Salvador',
    state: 'BA',
    coordinates: [-12.944156, -38.447779],
    zoom: 12,
    capital: true,
  },
  147: {
    name: 'Maceió',
    state: 'AL',
    coordinates: [-9.64424, -35.726299],
    capital: true,
    zoom: 12,
  },
  4538: {
    name: 'Itajai',
    state: 'SC',
    coordinates: [-26.903, -48.687808],
    zoom: 12,
    zoning: true,
  },
  4539: {
    name: 'Itapema',
    state: 'SC',
    coordinates: [-27.10648, -48.615859],
    zoom: 13,
  },
  4546: {
    name: 'Jaragua do Sul',
    state: 'SC',
    coordinates: [-26.487847, -49.093085],
    zoom: 13,
  },
  4460: {
    name: 'Brusque',
    state: 'SC',
    coordinates: [-27.097864, -48.918955],
    zoom: 13,
  },
  4464: {
    name: 'Camboriu',
    state: 'SC',
    coordinates: [-27.018223, -48.656501],
    zoom: 13,
  },
  4444: {
    name: 'Barra Velha',
    state: 'SC',
    coordinates: [-26.633159, -48.684626],
    zoom: 13,
  },
  4606: {
    name: 'Piçarras',
    state: 'SC',
    coordinates: [-26.765436, -48.673273],
    zoom: 13,
  },
  4603: {
    name: 'Penha',
    state: 'SC',
    coordinates: [-26.788085, -48.627405],
    zoom: 13,
  },
  4581: {
    name: 'Navegantes',
    state: 'SC',
    coordinates: [-26.89639, -48.647123],
    zoom: 13,
  },
  4615: {
    name: 'Porto Belo',
    state: 'SC',
    coordinates: [-27.154361, -48.542093],
    zoom: 13,
  },
  4455: {
    name: 'Bombinhas',
    state: 'SC',
    coordinates: [-27.150357, -48.487945],
    zoom: 13,
  },
  4814: {
    name: 'Campinas',
    state: 'SP',
    coordinates: [-22.909146, -47.062697],
    zoom: 12,
  },
  2806: {
    name: 'Araucária',
    state: 'PR',
    coordinates: [-25.585761, -49.403393],
    zoom: 12,
  },
  3135: {
    name: 'São José dos Pinhais',
    state: 'PR',
    coordinates: [-25.536633, -49.200866],
    zoom: 12,
  },
  3050: {
    name: 'Pinhais',
    state: 'PR',
    coordinates: [-25.433356, -49.184366],
    zoom: 12,
  },
  2843: {
    name: 'Campo Largo',
    state: 'PR',
    coordinates: [-25.460538, -49.530552],
    zoom: 12,
  },
  2863: {
    name: 'Colombo',
    state: 'PR',
    coordinates: [-25.365116, -49.185808],
    zoom: 12,
  },
  4920: {
    name: 'Guarulhos',
    state: 'SP',
    coordinates: [-23.455597, -46.53386],
    zoom: 12,
  },
  4593: {
    name: 'Palhoça',
    state: 'SC',
    coordinates: [-27.646675, -48.670443],
    zoom: 12,
    owners: true,
  },
  3060: {
    name: 'Ponta Grossa',
    state: 'PR',
    coordinates: [-25.098654, -50.158899],
    zoom: 12,
  },
  2894: {
    name: 'Fazenda Rio Grande',
    state: 'PR',
    coordinates: [-25.661333, -49.308249],
    zoom: 12,
  },
  4660: {
    name: 'São José',
    state: 'SC',
    coordinates: [-27.617615, -48.633764],
    zoom: 12,
  },
  5565: {
    name: 'Lisboa',
    state: 'PT',
    coordinates: [38.725729, -9.150378],
    zoom: 12,
  },
  5095: {
    name: 'Osasco',
    state: 'SP',
    coordinates: [-23.5329074, -46.7919731],
    zoom: 12,
  },
  4773: {
    name: 'Bauru',
    state: 'SP',
    coordinates: [-22.3220609, -49.0641097],
    zoom: 12,
  },
  4430: {
    name: 'Araquari',
    state: 'SC',
    coordinates: [-26.3737595, -48.7196053],
    zoom: 12,
  },
  2904: {
    name: 'Foz do Iguaçu',
    state: 'PR',
    coordinates: [-25.5184296, -54.5596249],
    zoom: 12,
  },
  4919: {
    name: 'Guarujá',
    state: 'SP',
    coordinates: [-23.9949104, -46.2568758],
    zoom: 12,
  },
  3172: {
    name: 'Umuarama',
    state: 'PR',
    coordinates: [-23.7639812, -53.3129081],
    zoom: 12,
  },
  5167: {
    name: 'Praia Grande',
    state: 'SP',
    coordinates: [-24.0312303, -46.4936278],
    zoom: 12,
  },
  5253: {
    name: 'São Caetano do Sul',
    state: 'SP',
    coordinates: [-23.6254591, -46.5624982],
    zoom: 14,
  },
  5254: {
    name: 'São Carlos',
    state: 'SP',
    coordinates: [-22.0136847, -47.9192657],
    zoom: 12,
  },
  5195: {
    name: 'Ribeirão Preto',
    state: 'SP',
    coordinates: [-21.1796474, -47.8103848],
    zoom: 12,
  },
  5277: {
    name: 'São Vicente',
    state: 'SP',
    coordinates: [-23.95771, -46.400745],
    zoom: 12,
  },
  5289: {
    name: 'Sorocaba',
    state: 'SP',
    coordinates: [-23.4868303, -47.4663812],
    zoom: 12,
  },
  5264: {
    name: 'São José do Rio Preto',
    state: 'SP',
    coordinates: [-20.811761, -49.3762272],
    zoom: 12,
  },
  5252: {
    name: 'São Bernardo do Campo',
    state: 'SP',
    coordinates: [-23.711168, -46.565661],
    zoom: 12,
  },
  3929: {
    name: 'Canoas',
    state: 'RS',
    coordinates: [-29.913788, -51.178152],
    zoom: 12,
  },
  78: {
    name: 'Vitória',
    state: 'ES',
    coordinates: [-20.282233, -40.298797],
    zoom: 12,
    zoning: true,
    capital: true,
  },
  4943: {
    name: 'Indaiatuba',
    state: 'SP',
    coordinates: [-23.1008979, -47.2207027],
    zoom: 12,
    zoning: true,
  },
  5312: {
    name: 'Taubaté',
    state: 'SP',
    coordinates: [-23.041115, -45.6129582],
    zoom: 12,
  },
  5001: {
    name: 'Jundiaí',
    state: 'SP',
    coordinates: [-23.1889378, -46.9022192],
    zoom: 12,
  },
  4724: {
    name: 'Americana',
    state: 'SP',
    coordinates: [-22.73626, -47.320929],
    zoom: 12,
  },
  5141: {
    name: 'Piracicaba',
    state: 'SP',
    coordinates: [-22.7305778, -47.6497392],
    zoom: 12,
  },
  4770: {
    name: 'Barueri',
    state: 'SP',
    coordinates: [-23.505705, -46.876303],
    zoom: 12,
  },
  5051: {
    name: 'Mogi das Cruzes',
    state: 'SP',
    coordinates: [-23.5287858, -46.1995372],
    zoom: 12,
  },
  5221: {
    name: 'Santa Bárbara d`Oeste',
    state: 'SP',
    coordinates: [-22.7496628, -47.4122852],
    zoom: 12,
  },
  5265: {
    name: 'São José dos Campos',
    state: 'SP',
    coordinates: [-23.2205841, -45.8954107],
    zoom: 12,
  },
  3663: {
    name: 'São Gonçalo',
    state: 'RJ',
    coordinates: [-22.8251061, -43.006424],
    zoom: 12,
  },
  3637: {
    name: 'Nilópolis',
    state: 'RJ',
    coordinates: [-22.8095862, -43.4259074],
    zoom: 15,
  },
  3640: {
    name: 'Nova Iguaçu',
    state: 'RJ',
    coordinates: [-22.7126265, -43.4807483],
    zoom: 12,
  },
  3615: {
    name: 'Duque de Caxias',
    state: 'RJ',
    coordinates: [-22.6585948, -43.2940172],
    zoom: 12,
  },
  3638: {
    name: 'Niterói',
    state: 'RJ',
    coordinates: [-22.9093568, -43.0632572],
    zoom: 12,
  },
  3599: {
    name: 'Belford Roxo',
    state: 'RJ',
    coordinates: [-22.7319068, -43.3799362],
    zoom: 12,
  },
  3665: {
    name: 'São João de Meriti',
    state: 'RJ',
    coordinates: [-22.7859958, -43.3663272],
    zoom: 12,
  },
  5250: {
    name: 'Santos',
    state: 'SP',
    coordinates: [-23.9522358, -46.3358962],
    zoom: 12,
  },
  5240: {
    name: 'Santana de Parnaíba',
    state: 'SP',
    coordinates: [-23.443941, -46.922991],
    zoom: 14,
  },
  5344: {
    name: 'Vinhedo',
    state: 'SP',
    coordinates: [-23.0342798, -46.9839012],
    zoom: 12,
  },
  5337: {
    name: 'Valinhos',
    state: 'SP',
    coordinates: [-22.966666, -46.992409],
    zoom: 12,
  },
  4753: {
    name: 'Atibaia',
    state: 'SP',
    coordinates: [-23.121175, -46.566993],
    zoom: 12,
  },
  5084: {
    name: 'Nova Odessa',
    state: 'SP',
    coordinates: [-22.777903, -47.291943],
    zoom: 12,
  },
  4792: {
    name: 'Bragança Paulista',
    state: 'SP',
    coordinates: [-22.947174, -46.540585],
    zoom: 12,
  },
  4849: {
    name: 'Cosmópolis',
    state: 'SP',
    coordinates: [-22.637339, -47.187173],
    zoom: 12,
  },
  5052: {
    name: 'Mogi Guaçu',
    state: 'SP',
    coordinates: [-22.350985, -46.938954],
    zoom: 12,
  },
  5118: {
    name: 'Paulínia',
    state: 'SP',
    coordinates: [-22.758135, -47.158284],
    zoom: 12,
  },
  4988: {
    name: 'Jaguariúna',
    state: 'SP',
    coordinates: [-22.688955, -46.990235],
    zoom: 12,
  },
  4981: {
    name: 'Itupeva',
    state: 'SP',
    coordinates: [-23.156324, -47.047007],
    zoom: 12,
  },
  3315: {
    name: 'Recife',
    state: 'PE',
    coordinates: [-8.056679, -34.917903],
    zoom: 12,
    capital: true,
  },
  5242: {
    name: 'Santo André',
    state: 'SP',
    coordinates: [-23.749685, -46.470598],
    zoom: 12,
  },
  756: {
    name: 'Fortaleza',
    state: 'CE',
    coordinates: [-3.781797, -38.534048],
    zoom: 12,
    capital: true,
  },
  5353: {
    name: 'Aracaju',
    state: 'SE',
    coordinates: [-10.927954, -37.067581],
    zoom: 12,
    capital: true,
  },
  411: {
    name: 'Feira de Santana',
    state: 'BA',
    coordinates: [-12.253074, -38.951005],
    zoom: 12,
  },
  511: {
    name: 'Lauro de Freitas',
    state: 'BA',
    coordinates: [-12.870775, -38.321939],
    zoom: 12,
  },
  693: {
    name: 'Vitória da Conquista',
    state: 'BA',
    coordinates: [-14.860715, -40.839449],
    zoom: 12,
  },
  77: {
    name: 'Vila Velha',
    state: 'ES',
    coordinates: [-20.432391, -40.339867],
    zoom: 12,
  },
  3770: {
    name: 'Natal',
    state: 'RN',
    coordinates: [-5.807938, -35.211944],
    zoom: 12,
    capital: true,
  },
  3294: {
    name: 'Olinda',
    state: 'PE',
    coordinates: [-7.992715, -34.858615],
    zoom: 12,
  },
  2610: {
    name: 'Campina Grande',
    state: 'PB',
    coordinates: [-7.235427, -35.893841],
    zoom: 12,
  },
  2655: {
    name: 'João Pessoa',
    state: 'PB',
    coordinates: [-7.159976, -34.858328],
    zoom: 12,
    capital: true,
  },
  350: {
    name: 'Camaçari',
    state: 'BA',
    coordinates: [-12.709757, -38.329365],
    zoom: 12,
  },
  3270: {
    name: 'Jaboatão dos Guararapes',
    state: 'PE',
    coordinates: [-8.146446, -34.980225],
    zoom: 12,
  },
  5291: {
    name: 'Sumaré',
    state: 'SP',
    coordinates: [-22.823229, -47.253251],
    zoom: 12,
  },
  4980: {
    name: 'Itu',
    state: 'SP',
    coordinates: [-23.268325, -47.301234],
    zoom: 12,
  },
  3861: {
    name: 'Alvorada',
    state: 'RS',
    coordinates: [-30.004203, -51.066533],
    zoom: 12,
  },
  4031: {
    name: 'Gramado',
    state: 'RS',
    coordinates: [-29.380297, -50.871132],
    zoom: 12,
  },
  256: {
    name: 'Manaus',
    state: 'AM',
    coordinates: [-3.0473333, -60.0294574],
    capital: true,
    zoom: 12,
  },
  1314: {
    name: 'São Luís',
    state: 'MA',
    coordinates: [-2.5565639, -44.3115117],
    zoom: 12,
    capital: true,
  },
  1506: {
    name: 'Campo Grande',
    state: 'MS',
    coordinates: [-20.4819461, -54.6679308],
    capital: true,
    zoom: 12,
  },
  2436: {
    name: 'Belém',
    state: 'PA',
    coordinates: [-1.3109149, -48.5798323],
    zoom: 11,
    capital: true,
  },
  977: {
    name: 'Goiânia',
    state: 'GO',
    coordinates: [-16.6595014, -49.3540315],
    zoom: 12,
    capital: true,
  },
  5514: {
    name: 'Palmas',
    state: 'TO',
    coordinates: [-10.2499168, -48.4375593],
    zoom: 11,
    capital: true,
  },
  94: {
    name: 'Rio Branco',
    state: 'AC',
    coordinates: [-9.9905333, -67.9170772],
    zoom: 12,
    capital: true,
  },
  4400: {
    name: 'Boa Vista',
    state: 'RR',
    coordinates: [2.8330766, -60.7733176],
    zoom: 12,
    capital: true,
  },
  209: {
    name: 'Macapá',
    state: 'AP',
    coordinates: [0.0727613, -51.1552367],
    capital: true,
    zoom: 12,
  },
  4382: {
    name: 'Porto Velho',
    state: 'RO',
    coordinates: [-8.757133, -63.9000565],
    zoom: 13,
    capital: true,
  },
  1383: {
    name: 'Cuiabá',
    state: 'MT',
    coordinates: [-15.5895955, -56.1091688],
    zoom: 12,
    capital: true,
  },
  3582: {
    name: 'Teresina',
    state: 'PI',
    coordinates: [-5.2499069, -43.0656713],
    zoom: 9.75,
    capital: true,
  },
  4985: {
    name: 'Jacareí',
    state: 'SP',
    coordinates: [-23.2853129, -46.048169],
    zoom: 12,
  },
  1983: {
    name: 'Juiz de Fora',
    state: 'MG',
    coordinates: [-21.736264, -43.362241],
    zoom: 10,
  },
  4214: {
    name: 'Santa Maria',
    state: 'RS',
    coordinates: [-29.6869369, -53.8484689],
    zoom: 12,
  },
  2389: {
    name: 'Uberlândia',
    state: 'MG',
    coordinates: [-18.9204347, -48.3434217],
    zoom: 11,
    owners: true,
  },
  1636: {
    name: 'Betim',
    state: 'MG',
    coordinates: [-19.9411226, -44.2920776],
    zoom: 11,
  },
  1770: {
    name: 'Contagem',
    state: 'MG',
    coordinates: [-19.8921492, -44.1523503],
    zoom: 12,
  },
  2065: {
    name: 'Montes Claros',
    state: 'MG',
    coordinates: [-16.7278961, -43.9129296],
    zoom: 12,
  },
  2388: {
    name: 'Uberaba',
    state: 'MG',
    coordinates: [-19.7464847, -47.9852374],
    zoom: 12,
  },
  2201: {
    name: 'Ribeirão das Neves',
    state: 'MG',
    coordinates: [-19.771288, -44.1197232],
    zoom: 12,
  },
  5348: {
    name: 'Votorantim',
    state: 'SP',
    coordinates: [-23.5500581, -47.4729057],
    zoom: 13,
  },
  4827: {
    name: 'Carapicuíba',
    state: 'SP',
    coordinates: [-23.5468713, -46.8668629],
    zoom: 13,
  },
  4859: {
    name: 'Diadema',
    state: 'SP',
    coordinates: [-23.7036029, -46.6444079],
    zoom: 13,
  },
  4893: {
    name: 'Franca',
    state: 'SP',
    coordinates: [-20.527577, -47.4786032],
    zoom: 11,
  },
  4972: {
    name: 'Itaquaquecetuba',
    state: 'SP',
    coordinates: [-23.4642139, -46.3711505],
    zoom: 13,
  },
  5038: {
    name: 'Mauá',
    state: 'SP',
    coordinates: [-23.668386, -46.448492],
    zoom: 13,
  },
  4154: {
    name: 'Passo Fundo',
    state: 'RS',
    coordinates: [-28.255948, -52.40644],
    zoom: 11,
  },
  3605: {
    name: 'Campos dos Goytacazes',
    state: 'RJ',
    coordinates: [-21.748065, -41.323094],
    zoom: 11,
  },
  3645: {
    name: 'Petrópolis',
    state: 'RJ',
    coordinates: [-22.514798, -43.186614],
    zoom: 12,
  },
  17: {
    name: 'Cariacica',
    state: 'ES',
    coordinates: [-20.3142621, -40.4135753],
    zoom: 12,
  },
  70: {
    name: 'Serra',
    state: 'ES',
    coordinates: [-20.183717, -40.238612],
    zoom: 12,
  },
  3309: {
    name: 'Petrolina',
    state: 'PE',
    coordinates: [-9.372092, -40.507378],
    zoom: 12,
  },
  1483: {
    name: 'Várzea Grande',
    state: 'MT',
    coordinates: [-15.660489, -56.133441],
    zoom: 12,
  },
  2427: {
    name: 'Ananindeua',
    state: 'PA',
    coordinates: [-1.363777, -48.39055],
    zoom: 12,
  },
  898: {
    name: 'Anápolis',
    state: 'GO',
    coordinates: [-16.331802, -48.933864],
    zoom: 12,
  },
  106: {
    name: 'Barra de São Miguel',
    state: 'AL',
    coordinates: [-9.831535, -35.887303],
    zoom: 14,
  },
  741: {
    name: 'Caucaia',
    state: 'CE',
    coordinates: [-3.734886, -38.657552],
    zoom: 12,
  },
  4247: {
    name: 'São Leopoldo',
    state: 'RS',
    coordinates: [-29.760584, -51.150298],
    zoom: 12,
  },
  4137: {
    name: 'Novo Hamburgo',
    state: 'RS',
    coordinates: [-29.689497, -51.12195],
    zoom: 12,
  },
  3305: {
    name: 'Paulista',
    state: 'PE',
    coordinates: [-7.9154815, -34.8967521],
    zoom: 12,
  },
  3227: {
    name: 'Caruaru',
    state: 'PE',
    coordinates: [-8.277814, -35.975843],
    zoom: 12,
  },
  4960: {
    name: 'Itanhaém',
    state: 'SP',
    coordinates: [-24.189896, -46.797797],
    zoom: 12,
  },
  5131: {
    name: 'Peruíbe',
    state: 'SP',
    coordinates: [-24.299101, -46.972463],
    zoom: 12,
  },
  4192: {
    name: 'Rio Grande',
    state: 'RS',
    coordinates: [-32.123376, -52.127243],
    zoom: 11,
  },
  5349: {
    name: 'Votuporanga',
    state: 'SP',
    coordinates: [-20.414568, -49.974769],
    zoom: 12,
  },
  2084: {
    name: 'Nova Lima',
    state: 'MG',
    coordinates: [-20.012107, -43.862707],
    zoom: 12,
  },
  4791: {
    name: 'Botucatu',
    state: 'SP',
    coordinates: [-22.8793686, -48.4493365],
    zoom: 13,
  },
  3892: {
    name: 'Bento Gonçalves',
    state: 'RS',
    coordinates: [-29.1527987, -51.5166156],
    zoom: 13,
  },
  4509: {
    name: 'Governador Celso Ramos',
    state: 'SC',
    coordinates: [-27.3682814, -48.5625426],
    zoom: 12,
  },
  3779: {
    name: 'Parnamirim',
    state: 'RN',
    coordinates: [-5.9179523, -35.2187992],
    zoom: 12,
  },
  5172: {
    name: 'Presidente Prudente',
    state: 'SP',
    coordinates: [-22.1227238, -51.4160937],
    zoom: 12,
  },
  4925: {
    name: 'Hortolândia',
    state: 'SP',
    coordinates: [-22.871797, -47.204858],
    zoom: 13.25,
  },
  1123: {
    name: 'Valparaíso de Goiás',
    state: 'GO',
    coordinates: [-16.077038, -47.984551],
    zoom: 13.25,
  },
  1473: {
    name: 'Sinop',
    state: 'MT',
    coordinates: [-11.86022, -55.502238],
    zoom: 13,
  },
  5135: {
    name: 'Pindamonhangaba',
    state: 'SP',
    coordinates: [-22.934093, -45.453173],
    zoom: 13,
  },
  5397: {
    name: 'Nossa Senhora do Socorro',
    state: 'SE',
    coordinates: [-10.8531643, -37.1269791],
    zoom: 13,
  },
  5016: {
    name: 'Louveira',
    state: 'SP',
    coordinates: [-23.087187, -46.949196],
    zoom: 13.25,
  },
  4034: {
    name: 'Gravataí',
    state: 'RS',
    coordinates: [-29.910242, -50.993041],
    zoom: 12.5,
  },
  4479: {
    name: 'Chapecó',
    state: 'SC',
    coordinates: [-27.091886, -52.616154],
    zoom: 12.25,
  },
  4826: {
    name: 'Caraguatatuba',
    state: 'SP',
    coordinates: [-23.618945, -45.386624],
    zoom: 12.25,
  },
  4777: {
    name: 'Bertioga',
    state: 'SP',
    coordinates: [-23.804847, -46.0613],
    zoom: 12.5,
  },
  5329: {
    name: 'Ubatuba',
    state: 'SP',
    coordinates: [-23.443876, -45.074175],
    zoom: 12,
  },
  4081: {
    name: 'Lajeado',
    state: 'RS',
    coordinates: [-29.450333, -51.985811],
    zoom: 12.5,
  },
  3676: {
    name: 'Teresópolis',
    state: 'RJ',
    coordinates: [-22.419026, -42.972441],
    zoom: 13,
  },
  5152: {
    name: 'Poá',
    state: 'SP',
    coordinates: [-23.531611, -46.343825],
    zoom: 13.5,
  },
  4465: {
    name: 'Campo Alegre',
    state: 'SC',
    coordinates: [-26.144936, -49.231711],
    zoom: 12.5,
  },
  4750: {
    name: 'Arujá',
    state: 'SP',
    coordinates: [-23.399585, -46.319637],
    zoom: 13,
  },
}
