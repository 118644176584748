import React, { Component, Fragment } from 'react'
import { Table, Row, Col } from 'components/AlertTable/styles'
import { PaginateArray, DepaginateArray } from 'utils/arrayPagination'
import { formatDweller, formatOwner } from 'utils/formatDweller'
import { AutoComplete, Checkbox } from 'components/Form'
import { Button } from 'components/Buttons'
import { formatDocument } from 'utils/formatDocument'
import { formatTelephone } from 'utils/formatTelephone'
import { getObjectValues } from 'utils/getObjectValues'
import { debounce, deburr } from 'lodash'
import { getCityIdLocalStorage } from 'services/auth'
import { callRequest } from 'services/requestHandler'
import { SearchConsumer } from 'context/Search'
import baseCities from 'constants/cities'
import classNames from 'classnames'
import DwellersService from 'services/dwellers'
import I18n from 'locales'
import PinLogo from 'components/PinLogo'
import './index.css'

class Dwellers extends Component {
  constructor(props, context) {
    super(props, context)
    this.clearDataArrays()
    this.dwellersService = context.dwellerService || new DwellersService()
    this.state = {
      initialOwnerFlag:
        (baseCities[getCityIdLocalStorage()] &&
          baseCities[getCityIdLocalStorage()].owners) ||
        !baseCities[getCityIdLocalStorage()],
      ownerFlag:
        (baseCities[getCityIdLocalStorage()] &&
          baseCities[getCityIdLocalStorage()].owners) ||
        !baseCities[getCityIdLocalStorage()],
      selectedDweller: null,
      dwellerTab: false,
      telephonesTab: false,
      addressTab: false,
      partnersTab: false,
      companyTab: false,
      emailsTab: false,
      page: 0,
      sortName: 'asc',
      sortType: 'asc',
      sortFullAddress: 'asc',
      addressSuggestions: [],
      selectedSuggestion: '',
      query: '',
      loading: false,
      firstNumber: '',
      lastNumber: '',
      noResults: false,
      useAmount: 0,
      useMax: 5,
      paginatedOwnersData: [],
      paginatedData: [],
    }
    this.Materialize = window.M
  }

  componentWillMount() {
    this.checkUsesAmount()
  }

  checkUsesAmount = () => {
    callRequest(
      () => this.dwellersService.checkUsesAmount(),
      data => {
        data &&
          data.Max &&
          this.setState({ useAmount: data.QtdUsos, useMax: data.Max })
      },
    )
  }

  handleActivateTab = tab => {
    const defaultTabs = {
      dwellerTab: false,
      telephonesTab: false,
      addressTab: false,
      partnersTab: false,
      companyTab: false,
      emailsTab: false,
    }

    this.setState(state => ({ ...state, ...defaultTabs, [tab]: true }))
  }

  handleDwellersQuery = address => {
    this.setState({ query: address, selectedSuggestion: '' })
    this.debouncedDwellersQuery(address)
  }

  handleSearchOwners = () => {
    this.clearDataArrays()
    const {
      selectedSuggestion: { value },
      firstNumber,
      lastNumber,
      query,
    } = this.state
    const city = baseCities[getCityIdLocalStorage()]
    let options = {
      street: (value && value.logradouro) || query,
      cep: value && value.cep,
      city: (value && value.localidade) || city.name,
      uf: (value && value.uf) || city.state,
      numbers: [firstNumber || lastNumber, lastNumber || firstNumber],
    }
    this.setState({ noResults: false, loading: true, selectedDweller: null })
    this.context.mapService.geocode(
      `${options.street} ${options.numbers[0]}, ${options.city}`,
      results => {
        const location = results[0].geometry.location.toJSON()
        const coordinates = [location.lng, location.lat]
        callRequest(
          () => this.dwellersService.searchOwners(options, coordinates),
          data => {
            if (data.hits && data.hits.hits.length > 0) {
              this.props.setDwellersStep && this.props.setDwellersStep()
              this.ownersData = data.hits.hits.map(item =>
                formatOwner(item._source),
              )
              this.setState({
                paginatedOwnersData: PaginateArray(this.ownersData),
                paginatedData: [],
              })
              this.data = []
              this.sortBy('Name')
            } else {
              this.setState({ noResults: true })
            }
            this.setState({ loading: false }, () => this.checkUsesAmount())
          },
          null,
          this.handleError,
          null,
        )
      },
    )
  }

  getAddresses = address => {
    const city = baseCities[getCityIdLocalStorage()]
      ? baseCities[getCityIdLocalStorage()]
      : baseCities[5270]
    const body = {
      address,
      uf: city.state.trim(),
      city: city.name.trim(),
    }
    return this.dwellersService.searchCepByAddress(body).then(
      ({ data }) =>
        (data.length > 0 &&
          data[0].logradouro &&
          data.map(item => {
            const address = `${item.logradouro}, ${item.bairro}, ${
              item.localidade
            }, ${item.uf}, ${item.cep}`
            return {
              name: address,
              value: item,
            }
          })) ||
        this.state.addressSuggestions ||
        [],
    )
  }

  getAddressSuggestions = async query => {
    const addresses = await this.getAddresses(query)
    this.setState({ addressSuggestions: addresses })
  }

  debouncedDwellersQuery = debounce(this.getAddressSuggestions, 300)

  handleDwellersQueryOnChange = item => {
    this.setState({ query: item.name, selectedSuggestion: item })
  }

  clearDataArrays = () => {
    this.data = []
    this.ownersData = []
    this.setState({ paginatedData: [], paginatedOwnersData: [] })
  }

  handleLocationSearch = () => {
    this.clearDataArrays()
    const {
      selectedSuggestion: { value },
      firstNumber,
      lastNumber,
      query,
    } = this.state
    this.setState(
      { loading: true, selectedDweller: null, noResults: false },
      () => {
        const city = baseCities[getCityIdLocalStorage()]
        const payload = {
          street: (value && value.logradouro) || query,
          cep: value && value.cep,
          city: (value && value.localidade) || city.name,
          uf: (value && value.uf) || city.state,
          numbers: [firstNumber || lastNumber, lastNumber || firstNumber],
        }

        callRequest(
          () => this.dwellersService.searchByLocation(payload),
          data => this.handleLocationResponse(data),
          null,
          this.handleError,
          () => this.setState({ loading: false }),
        )
      },
    )
  }

  handleLocationResponse = data => {
    const dwellers = data.result || data
    if (dwellers.length > 0) {
      const result = dwellers.map(item => {
        item.enderecoCompleto = ''
          .concat(item.enderecos[0].logradouro && item.enderecos[0].logradouro)
          .concat(
            `${(item.enderecos[0].numero &&
              ', Número: ' + item.enderecos[0].numero) ||
              '  '}`,
          )
          .concat(
            `${(item.enderecos[0].bairro &&
              ', Bairro: ' + item.enderecos[0].bairro) ||
              '  '}`,
          )
          .concat(
            `${(item.enderecos[0].cidade &&
              ', Cidade: ' + item.enderecos[0].cidade) ||
              '  '}`,
          )
          .concat(
            `${(item.enderecos[0].complemento &&
              ', Complemento: ' + item.enderecos[0].complemento) ||
              '  '}`,
          )
          .replace(/(\s\s)|(null, )/g, '')
          .trim()
        return item
      })
      this.props.setDwellersStep && this.props.setDwellersStep()
      this.clearDataArrays()
      this.data = result
      this.setState({
        paginatedData: PaginateArray(result),
        paginatedOwnersData: [],
      })
      this.sortBy('Name')
    } else {
      this.setState({ noResults: true })
    }
  }

  filterDwellers = (value, ownerFlag) => {
    let dwellers = DepaginateArray(
      this.ownersData.length > 0 ? this.ownersData : this.data,
    )
    let result = []
    if (value) {
      result = PaginateArray(
        dwellers.filter(item =>
          deburr(getObjectValues(item))
            .replace(
              /(\d\d\/\d\d\/\d\d\d\d)|(\d{14})|(\d{11})|([x|X]+(\d|\d{2}|\d{3}|\d{4})[x|X]+)|,/g,
              '',
            )
            .toLowerCase()
            .includes(deburr(value).toLowerCase()),
        ),
      )
    } else {
      result = PaginateArray(dwellers)
    }

    if (this.ownersData.length > 0)
      this.setState({ paginatedOwnersData: result, page: 0, paginatedData: [] })
    else
      this.setState({ paginatedData: result, page: 0, paginatedOwnersData: [] })
  }

  handleMatchOwners = owner => {
    this.setState({ loading: true, selectedDweller: null })
    callRequest(
      () => this.dwellersService.matchDocumentOwners(owner),
      data => {
        if (data.result && data.result.length > 1) {
          this.ownersData = []
          this.ownersPaginatedData = []
          this.handleLocationResponse(data.result)
          this.setState({ loading: false })
        } else if (data.result && data.result.length === 1) {
          this.handleDocumentSearch(data.result[0].documento)
        } else {
          this.showToast({
            html: I18n.t('dwellers.errorMatchDocument'),
            displayLength: 10000,
          })
          this.setState({ loading: false })
        }
      },
      null,
      e => {
        this.showToast({
          html: I18n.t('dwellers.errorMatchDocument'),
          displayLength: 10000,
        })
        this.setState({ loading: false })
        this.handleError(e)
      },
    )
  }

  handleCallOwners = owner => {
    if (
      !owner.document ||
      owner.document.includes('X') ||
      owner.document.length <= 4
    )
      this.handleMatchOwners(owner)
    else this.handleDocumentSearch(owner.document)
  }

  showToast = ({
    html = I18n.t('common.fail'),
    displayLength = 4000,
    classes = 'Search__Toast--failure',
  }) => {
    this.Materialize.toast({
      html,
      classes,
      displayLength,
    })
  }

  handleError = err => {
    err.response &&
      err.response.status === 404 &&
      this.showToast({
        html: 'Não encontrado',
        classes: 'Toast--failure',
      })
  }

  handleDocumentSearch = doc => {
    this.setState(
      {
        loading: true,
      },
      () => {
        callRequest(
          () => this.dwellersService.searchByDocument(doc),
          data => {
            this.handleDocumentSearchResponse(data)
          },
          null,
          this.handleError,
          () => this.setState({ loading: false }),
        )
      },
    )
  }

  handleDocumentSearchResponse = data => {
    this.setState(
      {
        useAmount: data.qtdUsos,
        selectedDweller: formatDweller(data.result),
      },
      () =>
        this.handleActivateTab(
          data.result.documento.length <= 12 ? 'dwellerTab' : 'companyTab',
        ),
    )
  }

  sortBy = column => {
    const { ownerFlag, paginatedOwnersData, paginatedData } = this.state
    let dwellers = DepaginateArray(
      ownerFlag ? paginatedOwnersData : paginatedData,
    )
    let sortFunction = null
    if (column) {
      if (!ownerFlag) {
        switch (column) {
          case 'Name':
            sortFunction = (a, b) => {
              let propA = a.tipoPessoa.includes('sica')
                ? a.dadosPf.nome
                : a.dadosPj.razaoSocial
              let propB = b.tipoPessoa.includes('sica')
                ? b.dadosPf.nome
                : b.dadosPj.razaoSocial
              if (propA < propB)
                return this.state[`sort${column}`] === 'asc' ? -1 : 1
              if (propA > propB)
                return this.state[`sort${column}`] === 'asc' ? 1 : -1
              return 0
            }
            break
          case 'Type':
            sortFunction = (a, b) => {
              if (a.tipoPessoa < b.tipoPessoa)
                return this.state[`sort${column}`] === 'asc' ? -1 : 1
              if (a.tipoPessoa > b.tipoPessoa)
                return this.state[`sort${column}`] === 'asc' ? 1 : -1
              return 0
            }
            break
          case 'FullAddress':
            sortFunction = (a, b) => {
              if (!a.enderecos[0].numero) {
                return this.state[`sort${column}`] === 'asc' ? 1 : -1
              }
              if (!b.enderecos[0].numero) {
                return this.state[`sort${column}`] === 'asc' ? -1 : 1
              }
              if (Number(a.enderecos[0].numero) < Number(b.enderecos[0].numero))
                return this.state[`sort${column}`] === 'asc' ? -1 : 1
              if (Number(a.enderecos[0].numero) > Number(b.enderecos[0].numero))
                return this.state[`sort${column}`] === 'asc' ? 1 : -1
              return 0
            }
            break
          default:
            return
        }
        this.setState({
          paginatedData: PaginateArray(dwellers.sort(sortFunction)),
          paginatedOwnersData: [],
        })
        this.ownersData = []
      } else {
        switch (column) {
          case 'FullAddress':
            sortFunction = (a, b) => {
              if (b.Complement === null || a.Complement < b.Complement)
                return this.state[`sort${column}`] === 'asc' ? -1 : 1
              if (a.Complement === null || a.Complement > b.Complement)
                return this.state[`sort${column}`] === 'asc' ? 1 : -1
              return 0
            }
            break
          default:
            sortFunction = (a, b) => {
              if (a[column] < b[column])
                return this.state[`sort${column}`] === 'asc' ? -1 : 1
              if (a[column] > b[column])
                return this.state[`sort${column}`] === 'asc' ? 1 : -1
              return 0
            }
            break
        }
        this.setState({
          paginatedOwnersData: PaginateArray(dwellers.sort(sortFunction)),
        })
      }
    } else {
      if (!ownerFlag) {
        this.setState({
          paginatedData: PaginateArray(dwellers),
          paginatedOwnersData: [],
        })
        this.ownersData = []
      } else {
        this.setState({
          paginatedOwnersData: PaginateArray(dwellers),
          paginatedData: [],
        })
        this.data = []
      }
    }
    this.setState({
      page: 0,
      sortName: 'asc',
      sortType: 'asc',
      sortFullAddress: 'asc',
      [`sort${column}`]: this.state[`sort${column}`] === 'asc' ? 'desc' : 'asc',
    })
  }

  renderHeaderCol = (title, width = '14.2%', sort) => (
    <Col
      header
      title={title}
      width={width}
      onClick={() => sort && this.sortBy(sort)}
      className={`${sort && 'Dwellers__Sortable'}`}
    >
      <span>{title}</span>
      {sort && (
        <i className="material-icons">
          {`keyboard_arrow_${
            this.state[`sort${sort}`] === 'asc' ? 'down' : 'up'
          }`}
        </i>
      )}
    </Col>
  )

  renderHeader = () => {
    const { ownerFlag } = this.state
    return (
      <Row header>
        {this.renderHeaderCol(I18n.t('generalData.name'), '30%', 'Name')}
        {this.renderHeaderCol(I18n.t('dwellers.type'), '14.2%', 'Type')}
        {this.renderHeaderCol(
          I18n.t('dwellers.address'),
          '35.8%',
          'FullAddress',
        )}
        <div className="Dwellers__FilterInputContainer">
          <input
            placeholder={I18n.t('dwellers.filter')}
            className="Dwellers__FilterInput"
            onChange={({ target: { value } }) =>
              this.filterDwellers(value.toLowerCase().trim(), ownerFlag)
            }
          />
        </div>
      </Row>
    )
  }
  renderCol = (title, label, width = '14.2%', optional = null) => (
    <Col width={width} title={title} label={label}>
      {title || I18n.t('common.notInformed')}
      {label === I18n.t('register.telephone') && optional.isCelphoneNumber && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="Dwellers__WppBtn"
          href={`https://api.whatsapp.com/send?phone=55${optional.raw}`}
        >
          <i style={{ fontSize: 16 }} className="fab fa-whatsapp" />
        </a>
      )}
    </Col>
  )

  renderSelectedDweller = selectedDweller => {
    return (
      <Fragment>
        <div className="Dwellers__HeaderDweller">
          <button
            className="Dwellers__Btn"
            onClick={() => this.setState({ selectedDweller: null })}
          >
            <i className="material-icons">keyboard_arrow_left</i>
            Voltar
          </button>
        </div>
        <div className="Dwellers__TabScrollContainer">
          <div className="Dwellers__TabsContainer">
            {!selectedDweller.companyInfo ? (
              this.renderTab(I18n.t('dwellers.dweller'), 'dwellerTab')
            ) : (
              <Fragment>
                {this.renderTab(I18n.t('dwellers.company'), 'companyTab')}
                {selectedDweller.companyData &&
                  this.renderTab(I18n.t('dwellers.partners'), 'partnersTab')}
              </Fragment>
            )}
            {selectedDweller.telephones &&
              selectedDweller.telephones.length > 0 &&
              this.renderTab(I18n.t('dwellers.telephones'), 'telephonesTab')}
            {selectedDweller.emails &&
              selectedDweller.emails.length > 0 &&
              this.renderTab(I18n.t('dwellers.emails'), 'emailsTab')}
            {this.renderTab(I18n.t('dwellers.addresses'), 'addressTab')}
          </div>
        </div>
        <div className="Dwellers__TabContent">{this.renderTabContent()}</div>
      </Fragment>
    )
  }

  renderRow = (item, id) => {
    if (!id) {
      id = Math.random()
        .toString(36)
        .substring(2, 5)
    }

    return (
      <Row
        hover
        onClick={() => this.handleDocumentSearch(item.documento)}
        key={
          item.dadosPf
            ? item.dadosPf.nome.concat(id)
            : (item.dadosPj.razaoSocial || item.dadosPj.nomeFantasia).concat(id)
        }
      >
        {this.renderCol(
          item.dadosPf
            ? item.dadosPf.nome
            : item.dadosPj.razaoSocial || item.dadosPj.nomeFantasia,
          I18n.t('generalData.name'),
          '30%',
        )}
        {this.renderCol(item.tipoPessoa, I18n.t('dwellers.type'))}
        {this.renderCol(
          item.enderecoCompleto,
          I18n.t('dwellers.fullAddress'),
          '55.8%',
        )}
      </Row>
    )
  }

  renderOwnersRow = (item, id) => {
    if (!id) {
      id = Math.random()
        .toString(36)
        .substring(2, 5)
    }
    return (
      <Row
        hover
        onClick={() => this.handleCallOwners(item)}
        key={`${item.name}${id}`}
      >
        {this.renderCol(item.Name, I18n.t('generalData.name'), '30%')}
        {this.renderCol(item.Type, I18n.t('dwellers.type'))}
        {this.renderCol(
          item.FullAddress,
          I18n.t('dwellers.fullAddress'),
          '25.8%',
        )}
        {this.renderCol(
          item.Complement,
          I18n.t('dwellers.complement'),
          '20.8%',
        )}
      </Row>
    )
  }

  renderNoResults = () => {
    return (
      <div>
        <br />
        Opa, nenhum resultado correspondente :(
      </div>
    )
  }

  renderTab = (title, tab) => {
    const classes = this.state[tab] ? 'Dwellers__Tab--active' : 'Dwellers__Tab'
    return (
      <div onClick={() => this.handleActivateTab(tab)} className={classes}>
        {title}
      </div>
    )
  }

  renderTabContent = () => {
    const {
      dwellerTab,
      addressTab,
      telephonesTab,
      companyTab,
      partnersTab,
      emailsTab,
      selectedDweller,
    } = this.state
    if (dwellerTab) {
      return (
        selectedDweller &&
        selectedDweller.personInfo && (
          <Fragment>
            <div className="Dwellers__TabRow">
              <strong>{I18n.t('dwellers.nameTitle')}</strong>
              <p>{selectedDweller.personInfo.name}</p>
            </div>
            <div className="Dwellers__TabRow">
              <strong>{I18n.t('dwellers.cpfTitle')}</strong>
              <p>{formatDocument(selectedDweller.document)}</p>
            </div>
            <div className="Dwellers__TabRow">
              <strong>{I18n.t('dwellers.motherTitle')}</strong>
              <p>{selectedDweller.personInfo.mothersName}</p>
            </div>
            <div className="Dwellers__TabRow">
              <strong>{I18n.t('dwellers.birthDateTitle')}</strong>
              <p>{selectedDweller.personInfo.birthDate}</p>
            </div>
            <div className="Dwellers__TabRow">
              <strong>{I18n.t('dwellers.ageTitle')}</strong>
              <p>{selectedDweller.personInfo.age}</p>
            </div>
            <div className="Dwellers__TabRow">
              <strong>{I18n.t('dwellers.scholarshipTitle')}</strong>
              <p>{selectedDweller.personInfo.scholarship}</p>
            </div>
            <div className="Dwellers__TabRow">
              <strong>{I18n.t('dwellers.deathTitle')}</strong>
              <p>{selectedDweller.personInfo.death}</p>
            </div>
          </Fragment>
        )
      )
    } else if (addressTab) {
      return (
        <Fragment>
          <Table className="Dwellers__Table">
            <Row header>
              {this.renderHeaderCol(I18n.t('dwellers.street'), '20%')}
              {this.renderHeaderCol('Nº', '20%')}
              {this.renderHeaderCol(I18n.t('plans.complement'), '20%')}
              {this.renderHeaderCol(I18n.t('generalData.city'), '20%')}
              {this.renderHeaderCol(I18n.t('generalData.uf'), '20%')}
            </Row>
            <div className="Dwellers__ScrollContainer">
              {selectedDweller.addresses.map((address, index) => (
                <Row key={address.street + index}>
                  {this.renderCol(
                    address.street,
                    I18n.t('dwellers.street'),
                    '20%',
                  )}
                  {this.renderCol(address.number, 'Nº', '20%')}
                  {this.renderCol(
                    address.complement,
                    I18n.t('plans.complement'),
                    '20%',
                  )}
                  {this.renderCol(
                    address.city,
                    I18n.t('generalData.city'),
                    '20%',
                  )}
                  {this.renderCol(address.uf, I18n.t('generalData.uf'), '20%')}
                </Row>
              ))}
            </div>
          </Table>
        </Fragment>
      )
    } else if (telephonesTab) {
      return (
        <Fragment>
          <Table className="Dwellers__Table">
            <Row header>
              {this.renderHeaderCol(I18n.t('register.telephone'), '25%')}
              {this.renderHeaderCol(I18n.t('dwellers.provider'), '25%')}
              {this.renderHeaderCol(I18n.t('dwellers.relation'), '25%')}
              {this.renderHeaderCol(I18n.t('dwellers.name'), '25%')}
            </Row>
            <div className="Dwellers__ScrollContainer">
              {selectedDweller.telephones.map((telephone, index) => (
                <Row key={telephone.number + index}>
                  {this.renderCol(
                    formatTelephone(telephone.number),
                    I18n.t('register.telephone'),
                    '25%',
                    {
                      raw: telephone.number,
                      isCelphoneNumber:
                        telephone.provider &&
                        telephone.provider.includes('Celular'),
                    },
                  )}
                  {this.renderCol(
                    telephone.provider,
                    I18n.t('dwellers.provider'),
                    '25%',
                  )}
                  {this.renderCol(
                    telephone.relation,
                    I18n.t('dwellers.relation'),
                    '25%',
                  )}
                  {this.renderCol(
                    telephone.name,
                    I18n.t('dwellers.name'),
                    '25%',
                  )}
                </Row>
              ))}
            </div>
          </Table>
        </Fragment>
      )
    } else if (companyTab) {
      return (
        <Fragment>
          <div className="Dwellers__TabRow">
            <strong>{I18n.t('dwellers.cnae')}</strong>
            <p>{selectedDweller.companyInfo.cnae}</p>
          </div>
          <div className="Dwellers__TabRow">
            <strong>{I18n.t('dwellers.cnaeDescription')}</strong>
            <p>{selectedDweller.companyInfo.cnaeDescription}</p>
          </div>
          <div className="Dwellers__TabRow">
            <strong>{I18n.t('dwellers.legalNature')}</strong>
            <p>{selectedDweller.companyInfo.legalNature}</p>
          </div>
          <div className="Dwellers__TabRow">
            <strong>{I18n.t('dwellers.companyName')}</strong>
            <p>{selectedDweller.companyInfo.companyName}</p>
          </div>
          <div className="Dwellers__TabRow">
            <strong>{I18n.t('dwellers.fantasyName')}</strong>
            <p>{selectedDweller.companyInfo.fantasyName}</p>
          </div>
          <div className="Dwellers__TabRow">
            <strong>{I18n.t('dwellers.cnpj')}</strong>
            <p>{formatDocument(selectedDweller.document)}</p>
          </div>
          <div className="Dwellers__TabRow">
            <strong>{I18n.t('dwellers.openningDate')}</strong>
            <p>{selectedDweller.companyInfo.openingDate}</p>
          </div>
          <div className="Dwellers__TabRow">
            <strong>{I18n.t('dwellers.companyType')}</strong>
            <p>{selectedDweller.companyInfo.companySize}</p>
          </div>
          <div className="Dwellers__TabRow">
            <strong>{I18n.t('dwellers.employesNumber')}</strong>
            <p>{selectedDweller.companyInfo.workers}</p>
          </div>
        </Fragment>
      )
    } else if (partnersTab) {
      return (
        <Fragment>
          <Table className="Dwellers__Table">
            <Row header>
              {this.renderHeaderCol(I18n.t('dwellers.name'), '20%')}
              {this.renderHeaderCol(I18n.t('dwellers.ocupation'), '20%')}
              {this.renderHeaderCol(I18n.t('dwellers.document'), '20%')}
              {this.renderHeaderCol(I18n.t('dwellers.joinDate'), '20%')}
              {this.renderHeaderCol(I18n.t('dwellers.participation'), '20%')}
            </Row>
            <div className="Dwellers__ScrollContainer">
              {selectedDweller.companyData.map((employee, index) => (
                <Row key={employee.name + index}>
                  {this.renderCol(
                    employee.name,
                    I18n.t('generalData.name'),
                    '20%',
                  )}
                  {this.renderCol(
                    employee.ocupation,
                    I18n.t('dwellers.ocupation'),
                    '20%',
                  )}
                  {this.renderCol(
                    formatDocument(employee.document),
                    I18n.t('dwellers.document'),
                    '20%',
                  )}
                  {this.renderCol(
                    employee.joinDate,
                    I18n.t('dwellers.joinDate'),
                    '20%',
                  )}
                  {this.renderCol(
                    employee.participation,
                    I18n.t('dwellers.participation'),
                    '20%',
                  )}
                </Row>
              ))}
            </div>
          </Table>
        </Fragment>
      )
    } else if (emailsTab) {
      return (
        <Fragment>
          <Table className="Dwellers__Table">
            <Row header>
              {this.renderHeaderCol(I18n.t('dwellers.emails'), '100%')}
            </Row>
            <div className="Dwellers__ScrollContainer">
              {selectedDweller.emails.map((email, index) => (
                <Row key={email.email + index}>
                  {this.renderCol(
                    email.email,
                    I18n.t('register.emails'),
                    '100%',
                  )}
                </Row>
              ))}
            </div>
          </Table>
        </Fragment>
      )
    } else {
      return <div>{I18n.t('common.fail')}</div>
    }
  }

  renderDwellersTable = page => {
    const { paginatedData } = this.state
    return (
      <Fragment>
        <Table className="Dwellers__Table" data-tut="step-thirteen">
          {this.renderHeader()}
          {paginatedData.length > 0 ? (
            <div className="Dwellers__ScrollContainer">
              {paginatedData[page].map(item => {
                return this.renderRow(item)
              })}
            </div>
          ) : (
            this.renderNoResults()
          )}
        </Table>
        <div className="Dwellers__Footer">
          <button
            className={`Dwellers__Btn ${page === 0 &&
              'Dwellers__Btn--disabled'}`}
            disabled={page === 0}
            onClick={() => this.setState({ page: page - 1 })}
          >
            <i className="material-icons">keyboard_arrow_left</i>
          </button>

          {`${page + 1} / ${paginatedData.length}`}
          <button
            disabled={page >= paginatedData.length - 1}
            className={`Dwellers__Btn ${page >= paginatedData.length - 1 &&
              'Dwellers__Btn--disabled'}`}
            onClick={() => this.setState({ page: page + 1 })}
          >
            <i className="material-icons">keyboard_arrow_right</i>
          </button>
        </div>
      </Fragment>
    )
  }

  renderOwnersTable = page => {
    const { paginatedOwnersData } = this.state
    return (
      <Fragment>
        <Table className="Dwellers__Table" data-tut="step-thirteen">
          {this.renderHeader()}
          {paginatedOwnersData.length > 0 ? (
            <div className="Dwellers__ScrollContainer">
              {paginatedOwnersData[page].map(item => {
                return this.renderOwnersRow(item)
              })}
            </div>
          ) : (
            this.renderNoResults()
          )}
        </Table>
        {paginatedOwnersData.length > 1 && (
          <div className="Dwellers__Footer">
            <button
              className={`Dwellers__Btn ${page === 0 &&
                'Dwellers__Btn--disabled'}`}
              disabled={page === 0}
              onClick={() => this.setState({ page: page - 1 })}
            >
              <i className="material-icons">keyboard_arrow_left</i>
            </button>

            {`${page + 1} / ${paginatedOwnersData.length}`}
            <button
              disabled={page >= paginatedOwnersData.length - 1}
              className={`Dwellers__Btn ${page >=
                paginatedOwnersData.length - 1 && 'Dwellers__Btn--disabled'}`}
              onClick={() => this.setState({ page: page + 1 })}
            >
              <i className="material-icons">keyboard_arrow_right</i>
            </button>
          </div>
        )}
      </Fragment>
    )
  }

  renderLoading = () => {
    return (
      <div className="Dwellers__PinContainer">
        <PinLogo dark width={100} height={100} className="Dwellers__Pin" />
      </div>
    )
  }

  render() {
    const { visible, close, modal = true } = this.props
    const {
      initialOwnerFlag,
      selectedDweller,
      addressSuggestions,
      selectedSuggestion,
      page,
      noResults,
      query,
      firstNumber,
      lastNumber,
      loading,
      useAmount,
      useMax,
      ownerFlag,
    } = this.state
    const classes = classNames({
      Dwellers: visible && modal,
      'Dwellers--hidden': !visible,
    })
    const modalClasses = classNames({
      Dwellers__Modal: modal,
    })
    return (
      <div className={classes}>
        <div className={modalClasses}>
          <div className="Dwellers__Header">
            <div className="Dwellers__Header__Info">
              <h2>{initialOwnerFlag ? 'Proprietários' : 'Moradores'}</h2>
            </div>
            <div className="Dwellers__Header__Info">{`Usos: ${useAmount ||
              0}/${useMax}`}</div>
            <div className="Dwellers__Header__Icon">
              <i className="material-icons" onClick={close}>
                close
              </i>
            </div>
          </div>
          <form data-tut="step-twelve">
            <div className="Dwellers__InputsWrapper">
              <AutoComplete
                classes={{
                  inputComponent: 'Dwellers__SearchInput__Input',
                  inputWrapper: 'Dwellers__SearchInput',
                  optionContainer: 'AutoComplete__OptionContainer',
                }}
                className="Dwellers__SearchInput"
                autoComplete="off"
                name="address"
                placeholder={I18n.t('dwellers.searchPlaceholder')}
                value={query}
                onChange={(event, { newValue, method }) =>
                  method === 'type' && this.handleDwellersQuery(newValue)
                }
                suggestions={addressSuggestions}
                onSuggestionSelected={(event, { suggestion }) => {
                  this.handleDwellersQueryOnChange(suggestion)
                }}
              />
              <div className="Dwellers__NumContainer">
                <input
                  type="number"
                  value={firstNumber}
                  onChange={({ target: { value } }) =>
                    this.setState({ firstNumber: value })
                  }
                  placeholder={I18n.t('dwellers.startNumber')}
                  className="Dwellers__NumInput"
                />
                <input
                  type="number"
                  value={lastNumber}
                  onChange={({ target: { value } }) =>
                    this.setState({ lastNumber: value })
                  }
                  placeholder={I18n.t('dwellers.endNumber')}
                  className="Dwellers__NumInput"
                />
              </div>
            </div>
            <div className="Dwellers__InputsWrapper">
              {initialOwnerFlag && (
                <div className="Dwellers__OwnerInput">
                  <Checkbox
                    name="owners"
                    label="Possíveis Moradores"
                    checked={!ownerFlag}
                    disabled={loading}
                    onChange={() =>
                      this.setState(state => ({
                        ...state,
                        ownerFlag: !state.ownerFlag,
                      }))
                    }
                  />
                  <i
                    className="material-icons"
                    title={I18n.t('dwellers.helpTitle')}
                  >
                    help_outline
                  </i>
                </div>
              )}
              <Button
                btnStyle="primary"
                type="submit"
                className="Dwellers__SearchBtn"
                title={
                  ((!(selectedSuggestion || query) || !firstNumber) &&
                    'Preencha os Campos') ||
                  ((loading && 'Carregando...') || 'Buscar')
                }
                disabled={
                  !(selectedSuggestion || query) ||
                  !(firstNumber || lastNumber) ||
                  loading
                }
                onClick={e => {
                  e.preventDefault()
                  if (ownerFlag) this.handleSearchOwners()
                  else this.handleLocationSearch()
                }}
              >
                {I18n.t('components.actions.search')}
              </Button>
            </div>
          </form>
          <div className="Dwellers__Body">
            {(loading && this.renderLoading()) ||
              (!selectedDweller && (
                <Fragment>
                  {this.ownersData.length > 0 && this.renderOwnersTable(page)}
                  {this.data.length > 0 && this.renderDwellersTable(page)}
                  {/* {(paginate.length > 0 &&
                    this.renderOwnersTable(page)) ||
                    (this.data.length > 0 && this.renderDwellersTable(page))} */}
                </Fragment>
              )) ||
              this.renderSelectedDweller(selectedDweller)}
            {noResults && this.renderNoResults()}
          </div>
        </div>
      </div>
    )
  }
}

Dwellers.contextType = SearchConsumer

export default Dwellers
