import React from 'react'
import { Formik } from 'formik'
import { Field } from 'components/Form'
import { Grid, Col, Row } from 'components/Grid'
import { SearchConsumer } from 'context/Search'
import { Button } from 'components/Buttons'
import I18n from 'locales'
import { HOUSE, APARTMENT, COMERCIAL, OTHERS } from 'constants/categories'
import { getAllCategoriesAsArray } from 'utils/category'
import { userId, getCityIdLocalStorage } from 'services/auth'
import { saveAlerts } from 'services/alert'
import { callRequest } from 'services/requestHandler'
import { deburr } from 'lodash'
import * as Yup from 'yup'
import './index.css'

const ALL_CATEGORIES = { ...HOUSE, ...APARTMENT, ...COMERCIAL, ...OTHERS }

const AlertSchema = Yup.object().shape({
  alertName: Yup.string().required(I18n.t('login.required')),
})

const renderRange = (min, max, prefix = '', sufix = '', sufixSingular = '') => {
  if (min || max) {
    if (min === max) {
      return (
        <p>
          {`- ${prefix} ${min.toLocaleString('pt-BR')} ${
            min === 1 ? sufixSingular : sufix
          }`}
        </p>
      )
    } else if (min && !max)
      return <p>{`- ${prefix} ${min.toLocaleString('pt-BR')}+ ${sufix}`}</p>
    else if (!min && max) {
      return (
        <p>
          {`- ${I18n.t('common.to')} ${prefix} ${max.toLocaleString(
            'pt-BR',
          )} ${sufix}`}
        </p>
      )
    } else if (min && max) {
      return (
        <p>
          {`- ${I18n.t('common.from')} ${prefix} ${min.toLocaleString(
            'pt-BR',
          )} ${I18n.t('common.to')} ${prefix} ${max.toLocaleString(
            'pt-BR',
          )} ${sufix}`}
        </p>
      )
    }
  }
  return ''
}

const getCategoriesIds = categories => {
  const keys = Object.keys(categories)
  let ids = []

  for (let key of keys) {
    if (categories[key]) {
      const id = getAllCategoriesAsArray().filter(({ name }) => key === name)[0]
      if (id) {
        ids.push(id.value)
      }
    }
  }

  return ids.length > 0 ? ids : null
}

const submitAlert = (values, polygons, callback) => {
  let newPolygons = []
  polygons.forEach(polygon => {
    let step = ''
    polygon.forEach((coordinates, index) => {
      step +=
        index === polygon.length - 1
          ? coordinates.toString().replace(',', ' ')
          : `${coordinates.toString().replace(',', ' ')}, `
    })
    newPolygons.push(step)
  })
  const payload = {
    AreaMaxima: '',
    AreaMaximaTotal: values.maxTotalArea,
    AreaMinima: '',
    AreaMinimaTotal: values.minTotalArea,
    BairroId: '',
    CidadeId: getCityIdLocalStorage(),
    Endereco: '',
    Garagem: values.garages,
    ImobiliariaId: '0',
    Nome: values.alertName,
    OpcionaisIds: values.optionals,
    Particular: values.privateBusiness || false,
    PessoaId: userId(),
    PontosPoligonoZoneamento: [],
    PontosPoligono: newPolygons[0],
    Quarto: values.rooms,
    TestadaMaxima: '',
    TestadaMinima: '',
    TipoImovelIds: getCategoriesIds(values.category),
    TipoNegocio: deburr(values.businessType),
    ValorMaximo: values.maxPrice || null,
    ValorMinimo: values.minPrice || null,
    VariacaoMaxima: '',
    VariacaoMinima: '',
  }
  callRequest(
    () => saveAlerts(payload),
    () => {
      callback()
      window.M.toast({
        html: I18n.t('alert.alertCreated'),
        classes: 'Alert__Toast--success',
      })
    },
  )
}

const renderOptionsText = ({
  businessType,
  privateBusiness,
  minPrice,
  maxPrice,
  minTotalArea,
  maxTotalArea,
  rooms,
  garages,
  suits,
  category,
  optionals,
}) => {
  const selectedCategories = Object.keys(category).filter(
    key => category[key] && !key.includes('Filter'),
  )
  let categories = getCategoriesText(selectedCategories)
  return (
    <React.Fragment>
      <p>
        - {businessType} {privateBusiness ? I18n.t('alert.private') : ''}
      </p>
      <p>- {categories}</p>
      {renderRange(minPrice, maxPrice, 'R$')}
      {renderRange(minTotalArea, maxTotalArea, '', 'm²', 'm²')}
      {rooms && <p>{`- ${rooms}  quarto(s)`}</p>}
      {garages && <p>{`- ${garages}  garagem(s)`}</p>}
      {suits && <p>{`- ${suits}  suíte(s)`}</p>}
      {renderOptionals(optionals)}
    </React.Fragment>
  )
}

const renderOptionals = optionals => {
  let optionalsString = ''

  optionals.forEach((optional, index) => {
    if (index === 0) optionalsString += '- '
    if (index === optionals.length - 1) {
      optionalsString += optional
    } else {
      optionalsString += `${optional}, `
    }
  })

  return optionalsString
}

const getCategoriesText = categories => {
  const length = categories.length
  let string = ''
  let index = 0

  if (length === 3) {
    string = `${ALL_CATEGORIES[categories[0]].prettyName}, ${
      ALL_CATEGORIES[categories[1]].prettyName
    } e ${ALL_CATEGORIES[categories[2]].prettyName}`
  } else if (length === 2) {
    string = `${ALL_CATEGORIES[categories[0]].prettyName} e ${
      ALL_CATEGORIES[categories[1]].prettyName
    }`
  } else if (length === 1) {
    string = `${ALL_CATEGORIES[categories[0]].prettyName}`
  } else {
    for (let category of categories) {
      if (index === 2) {
        string += `${ALL_CATEGORIES[category].prettyName} `
        index++
      } else if (index > 2) {
        string += `e +${length - 3}`
        index++
        break
      } else {
        string += `${ALL_CATEGORIES[category].prettyName}, `
        index++
      }
    }
  }

  return string
}

const Alert = ({ onCancel, mobile }) => (
  <SearchConsumer>
    {({ prevSearch, polygons }) => {
      const {
        businessType,
        privateBusiness,
        priceRange,
        category,
        more,
      } = JSON.parse(prevSearch)
      const { minPrice, maxPrice } = priceRange || {}
      const { minTotalArea, maxTotalArea, rooms, suits, garages, optionals } =
        more || {}
      return (
        <Formik
          onSubmit={values => submitAlert(values, polygons, onCancel)}
          validationSchema={AlertSchema}
          initialValues={{
            alertName: '',
            businessType,
            privateBusiness,
            minPrice,
            maxPrice,
            minTotalArea,
            maxTotalArea,
            rooms,
            garages,
            suits,
            category,
            optionals,
          }}
        >
          {({ values, errors, ...props }) => (
            <form onSubmit={props.handleSubmit}>
              <Grid className="Alert">
                <Row>
                  <Col md={6} xs={12}>
                    <Row className="Alert__Row">
                      <Col xs={12}>
                        <h2 className="Alert__Header">
                          {I18n.t('components.searchBar.createAlert')}
                        </h2>
                        <Field
                          label={I18n.t('components.createAlert.alertName')}
                          placeholder={I18n.t(
                            'components.createAlert.alertNamePlaceholder',
                          )}
                          type="text"
                          name="alertName"
                          value={values.alertName}
                          onChange={({ target: { name, value } }) =>
                            props.setFieldValue(name, value)
                          }
                        />
                        {errors.alertName && (
                          <span className="Alert__Error">
                            {errors.alertName}
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row className="Alert__Row">
                      <Col xs={12}>
                        <span className="Alert__Info">
                          {I18n.t('components.createAlert.info')}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} xs={12}>
                    <Row className="Alert__Row">
                      <Col xs={12}>
                        <span className="Alert__Info">
                          {I18n.t('components.createAlert.options')}
                        </span>
                      </Col>
                    </Row>
                    <Row className="Alert__Row">
                      <Col xs={12}>{renderOptionsText(values)}</Col>
                    </Row>
                  </Col>
                </Row>
                {mobile ? (
                  <Row end="xs">
                    <Col xs={12}>
                      <Button
                        type="submit"
                        btnStyle="primary"
                        block
                        btnSize="large"
                      >
                        {I18n.t('components.actions.createAlert')}
                      </Button>
                      <Button
                        type="button"
                        btnStyle="flat"
                        onClick={onCancel}
                        block
                        btnSize="large"
                      >
                        {I18n.t('components.actions.cancel')}
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row end="xs">
                    <Col xs={12}>
                      <Button type="button" btnStyle="flat" onClick={onCancel}>
                        {I18n.t('components.actions.cancel')}
                      </Button>
                      <Button type="submit" btnStyle="primary">
                        {I18n.t('components.actions.createAlert')}
                      </Button>
                    </Col>
                  </Row>
                )}
              </Grid>
            </form>
          )}
        </Formik>
      )
    }}
  </SearchConsumer>
)

export default Alert
