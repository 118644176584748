import React, { Component } from 'react'
import classNames from 'classnames'
import { Form, createFilter, Formik } from 'components/Form'
import I18n from 'locales'
import { Col, Row } from 'components/Grid'
import { Button } from 'components/Buttons'
import {
  getHouseAsArray,
  getApartmentAsArray,
  getComercialAsArray,
  getOthersAsArray,
  getAllCategoriesAsArray,
} from 'utils/category'
import {
  renderMacroCheckbox,
  renderMicroCheckboxes,
  getCount,
} from './FilterCategoryUtils'
import './index.css'

const house = getHouseAsArray()
const apartment = getApartmentAsArray()
const comercial = getComercialAsArray()
const others = getOthersAsArray()
const all = getAllCategoriesAsArray()

class FilterCategory extends Component {
  constructor() {
    super()
    this.state = {
      active: false,
    }
  }

  toggleContainer = () => {
    const { active } = this.state
    this.setState({ active: !active })
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return
    }

    this.state.active && this.submit && this.submit()
  }

  render() {
    const { className, onSubmit, error, category } = this.props
    const { active } = this.state
    const classes = classNames('SearchFilter', 'FilterCategory', className)
    return (
      <div style={{ position: 'relative', zIndex: 2 }}>
        <div className={classes} data-tut="step-two">
          {createFilter({
            label: 'Tipo',
            error: error.category,
            count: getCount(category),
            icon: { size: 20 },
            isOpen: this.state.active,
            onClick: this.toggleContainer,
          })}
          {active && (
            <div className="FilterContainer">
              <Formik
                onSubmit={values => {
                  this.props.goToStep(undefined)
                  onSubmit({
                    category: Object.keys(values).length > 0 ? values : '',
                  })
                }}
                initialValues={category}
              >
                {({ values, ...props }) => {
                  this.submit = props.submitForm
                  return (
                    <Form>
                      <div
                        className="FilterCategory__OptionContainer"
                        ref={el => (this.node = el)}
                      >
                        {renderMacroCheckbox(
                          'allFilter',
                          'Todos',
                          props,
                          all,
                          values,
                        )}
                        {renderMacroCheckbox(
                          'apartmentFilter',
                          I18n.t('components.filters.apartment'),
                          props,
                          apartment,
                          values,
                        )}
                        {renderMicroCheckboxes(apartment, props, values)}
                        {renderMacroCheckbox(
                          'houseFilter',
                          I18n.t('components.filters.houses'),
                          props,
                          house,
                          values,
                        )}
                        {renderMicroCheckboxes(house, props, values)}
                        {renderMacroCheckbox(
                          'comercialFilter',
                          I18n.t('components.filters.comercial'),
                          props,
                          comercial,
                          values,
                        )}
                        {renderMicroCheckboxes(comercial, props, values)}
                        {renderMacroCheckbox(
                          'othersFilter',
                          I18n.t('components.filters.others'),
                          props,
                          others,
                          values,
                        )}
                        {renderMicroCheckboxes(others, props, values)}
                      </div>
                      <Row between="xs">
                        <Col>
                          <Button
                            className="FilterPriceRange__Popover__Cancel"
                            btnStyle="link"
                            onClick={this.toggleContainer}
                          >
                            {I18n.t('components.actions.cancel')}
                          </Button>
                        </Col>
                        <Col>
                          <Button btnStyle="link" type="submit">
                            {I18n.t('components.actions.ok')}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default FilterCategory
