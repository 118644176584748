import baseCities from 'constants/cities'

const getDefaultOptions = () => {
  if (baseCities[sessionStorage.getItem('cityId')]) {
    return {
      zoom: baseCities[sessionStorage.getItem('cityId')].zoom,
      coordinates: baseCities[sessionStorage.getItem('cityId')].coordinates,
    }
  } else {
    return {
      zoom: baseCities[5270].zoom,
      coordinates: baseCities[5270].coordinates,
    }
  }
}

const defaultOptions = getDefaultOptions()

export const MAP = {
  defaultBounds: [-180, -85, 180, 85],
  defaultZoom: defaultOptions.zoom,
  defaultCenter: defaultOptions.coordinates,
  options: {
    maxZoom: 20,
  },
}

export const MAP_MOBILE = {
  defaultBounds: [-180, -85, 180, 85],
  defaultZoom: defaultOptions.zoom,
  defaultCenter: defaultOptions.coordinates,
  options: {
    maxZoom: 18,
  },
}
