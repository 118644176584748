import axios from 'axios'
import bodybuilder from 'bodybuilder'
import { userId, headers } from 'services/auth'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALERT_URL = `${API_URL}/alerta/buscar?novaPlataforma=true`
const SAVE_ALERT_URL = `${API_URL}/alerta/cadastrar`
const DELETE_ALERT_URL = `${API_URL}/alerta/excluir`
const GET_ALERT_REALTY_URL = `${API_URL}/alerta/buscar/alertahistorico`
const GET_ALERTS_NOT_READED = `${API_URL}/alerta/verifica/naolido`
const UPDATE_ALERT_STATUS = `${API_URL}/alerta/atualizar`
const GET_ALERT_REALTY_PAGINATED = `${API_URL}/alerta/buscar/alertahistorico/v2`

export const getAlerts = () =>
  axios.get(`${GET_ALERT_URL}/${userId()}`, { headers })

export const saveAlerts = payload =>
  axios.post(SAVE_ALERT_URL, payload, { headers })

export const deleteAlert = alertId =>
  axios.post(`${DELETE_ALERT_URL}/${alertId}`, {}, { headers })

export const getAlertRealty = alertId =>
  axios.get(`${GET_ALERT_REALTY_URL}/${alertId}`, { headers })

export const getAlertsNotReaded = () =>
  axios.get(`${GET_ALERTS_NOT_READED}/${userId()}`, { headers })

export const updateAlertStatus = (alertId, status) =>
  axios.post(`${UPDATE_ALERT_STATUS}/${alertId}/${status}`, {}, { headers })

export const alertPaginated = (options = {}) => {
  const { source, filterPaths, sort, query, alertId, businessType } = options

  let body = bodybuilder()
  body.query('bool', b => b.queryMinimumShouldMatch(1))
  body.rawOption('_source', source)
  body.rawOption('alertaId', alertId)

  if (sort) {
    for (const sortColumn in sort) {
      if (sort.hasOwnProperty(sortColumn)) {
        const sortType = sort[sortColumn]
        body.sort(sortColumn, sortType)
      }
    }
  }

  if (query) {
    if (Number(query) <= Number.MAX_SAFE_INTERGER) {
      body.query('query_string', {
        query,
        fields: [
          'processed_address_formatted',
          businessType,
          'features_bedroom',
          'total_area',
          'area',
          'features_garage',
        ],
      })
    }
  } else {
    body.query('query_string', {
      query: `*${query}*`,
      default_operator: 'AND',
      analyze_wildcard: true,
      fields: [
        'link',
        'processed_address_formatted',
        'neighborhood_name',
        'processed_address_building_name',
        'broker_name',
      ],
    })
  }

  return axios.post(
    `${GET_ALERT_REALTY_PAGINATED}${
      filterPaths ? '?filter_path' + filterPaths.toString() : ''
    }`,
    {},
    { headers },
  )
}
