import I18n from 'locales'
import { logout, authed } from 'services/auth'

export const callRequest = (
  request,
  success = null,
  failure = null,
  errorHandler = null,
  final = null,
) => {
  request()
    .then(({ data, status, statusText }) => {
      if (status === 200) {
        success && success(data)
      } else {
        failure && failure()
        window.M.toast({ classes: 'Toast-failure', html: statusText })
      }
    })
    .catch(err => {
      errorHandler && errorHandler(err)
      if (err && err.response) {
        if (err.response.status === 401) {
          if (authed()) {
            logout()
            window.location = '/'
            window.M.toast({
              classes: 'Toast--failure',
              html:
                'Sua vigência expirou, entre em contato com nossa equipe ou adquira um novo plano',
            })
            window.location = '/preferencias#planos'
          } else {
            window.M.toast({
              html: I18n.t('common.fail'),
              classes: 'Toast--failure',
            })
          }
        } else if (err.response.status === 403) {
          window.M.toast({
            classes: 'Toast--failure',
            html:
              'Sua vigência expirou, entre em contato com nossa equipe ou adquira um novo plano',
          })
          window.location = '/preferencias#planos'
        } else {
          window.M.toast({
            html: I18n.t('common.fail'),
            classes: 'Toast--failure',
          })
        }
      }
    })
    .finally(() => {
      final && final()
    })
}
