import Cookies from 'js-cookie'
import axios from 'axios'
import I18n from 'locales'
import { get, set } from 'lodash'
import moment from 'moment'

const API_URL = process.env.REACT_APP_API_URL
const FORGOT_PASSWORD_URL = `${API_URL}/auth/enviarnovasenha?email=`
const DOMAIN = window.location.hostname === 'localhost' ? 'localhost' : '.eemovel.com.br'
const GET_TOKEN = `${API_URL}/Token`
const GET_USER_INFO = `${API_URL}/Account/UserInfo`

export const setToken = token => {
  Cookies.set('eemovel_access_token', token, {
    domain: DOMAIN,
    expires: 5,
    path: '/',
  })
}

export const getToken = () => Cookies.get('eemovel_access_token')

export const headers = {
  'Access-Control-Allow-Origin': '*',
  Authorization: `Bearer ${getToken()}`,
}

export const specialSessions = {
  48: 'free',
}

export const forgotPassword = ({ email }) =>
  axios
    .post(`${FORGOT_PASSWORD_URL}${email.toLowerCase()}`)
    .then(({ status, statusText }) => {
      if (status === 200) {
        window.M.toast({
          html: 'Nova senha enviada com sucesso',
          classes: 'Toast--success',
        })
      } else {
        window.M.toast({
          html: statusText,
          classes: 'Toast--failure',
        })
      }
    })
    .catch(err => {
      console.log(err)
      window.M.toast({
        html: I18n.t('common.fail'),
        classes: 'Toast--failure',
      })
    })

export const storeUser = (value, override = false) => {
  let credential = {}
  if (override) {
    credential = {
      usuarioId: value.usuarioId,
      tipoPerfil: value.tipoPerfil,
      nome: value.nome,
      imobiliariaId: value.imobiliariaId,
      gestorId: value.gestorId,
      ufId: value.ufId,
      email: value.email,
      cidadeId: value.cidadeId,
      cidade: value.cidade,
      finalVigencia: value.finalVigencia,
      cpfCnpj: value.cpfCnpj,
      telefone: value.telefone,
      roles: value.roles,
    }
  } else {
    credential = {
      usuarioId: value.Id,
      tipoPerfil: value.ProfileType,
      nome: value.Name,
      imobiliariaId: value.RealStateAgencyId,
      gestorId: value.ManagerId,
      ufId: value.StateId,
      email: value.Email,
      cidadeId: value.CityId,
      finalVigencia: value.SubscriptionExpiresIn,
      cidade: value.City,
      telefone: value.PhoneNumber,
      roles: value.Roles,
    }
  }

  return Cookies.set('eemovel_credential', credential, {
    domain: DOMAIN,
    expires: 5,
    path: '/',
  })
}

export const getUserInfo = token =>
  axios.get(GET_USER_INFO, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

export const authed = () => !!userId()

export const credential = () => Cookies.getJSON('eemovel_credential')

export const userId = () => get(credential(), 'usuarioId')

export const updateCredential = ({ name, city, uf, phone }) => {
  const newCredential = credential()
  name && set(newCredential, 'nome', name)
  city && set(newCredential, 'cidadeId', Number(city))
  uf && set(newCredential, 'ufId', Number(uf))
  phone && set(newCredential, 'telefone', phone)
  city && sessionStorage.setItem('cityId', Number(city))
  storeUser(newCredential, true)
}

export const userInfo = () => ({
  userId: get(credential(), 'usuarioId'),
  username: get(credential(), 'nome'),
  email: get(credential(), 'email'),
  cityId: get(credential(), 'cidadeId'),
  managerId: get(credential(), 'gestorId'),
  realStateId: get(credential(), 'imobiliariaId'),
  profileType: get(credential(), 'tipoPerfil'),
  ufId: get(credential(), 'ufId'),
  cpfCnpj: get(credential(), 'cpfCnpj'),
  phone: get(credential(), 'telefone'),
  roles: get(credential(), 'roles'),
})

export const cityId = () => get(credential(), 'cidadeId') || 5270

export const login = (email, password, impersonate = null) => {
  let params = new URLSearchParams()
  params.append('username', email)
  params.append('password', password)
  params.append('grant_type', 'password')
  impersonate && params.append('impersonate', impersonate)

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }

  return axios.post(GET_TOKEN, params, config)
}

export const getCityIdLocalStorage = () => sessionStorage.getItem('cityId')
export const setCityIdLocalStorage = cityId =>
  sessionStorage.setItem('cityId', cityId)

export const logout = () => {
  Cookies.remove('eemovel_credential', {
    domain: DOMAIN,
    path: '/',
  })
  Cookies.remove('eemovel_access_token', {
    domain: DOMAIN,
    path: '/',
  })
  sessionStorage.removeItem('cityId')
}

export const verifyValidity = () => {
  const now = moment()
  const validityEnd = moment(get(credential(), 'finalVigencia'))
  if (authed() && (!validityEnd || now.isAfter(validityEnd))) {
    // let reason = ''
    if (now.isAfter(validityEnd)) {
      // reason = '/validityexpired'
      window.M.toast({
        classes: 'Toast--failure',
        html:
          'Sua vigência expirou, entre em contato com nossa equipe ou adquira um novo plano',
      })
    }
    // logout()
    // !window.location.pathname.match(/\/login(\/\w+){0,1}/) &&
    window.location.replace('/preferencias#planos')
  }
}
