import { HOUSE, APARTMENT, COMERCIAL, OTHERS } from 'constants/categories'

export const getHouseAsArray = () => {
  let comercialArray = []

  for (let item of Object.keys(HOUSE)) {
    comercialArray.push({
      name: item,
      value: HOUSE[item].value,
      prettyName: HOUSE[item].prettyName,
    })
  }

  return comercialArray
}

export const getApartmentAsArray = () => {
  let comercialArray = []

  for (let item of Object.keys(APARTMENT)) {
    comercialArray.push({
      name: item,
      value: APARTMENT[item].value,
      prettyName: APARTMENT[item].prettyName,
    })
  }

  return comercialArray
}

export const getComercialAsArray = () => {
  let comercialArray = []

  for (let item of Object.keys(COMERCIAL)) {
    comercialArray.push({
      name: item,
      value: COMERCIAL[item].value,
      prettyName: COMERCIAL[item].prettyName,
    })
  }

  return comercialArray
}

export const getOthersAsArray = () => {
  let comercialArray = []

  for (let item of Object.keys(OTHERS)) {
    comercialArray.push({
      name: item,
      value: OTHERS[item].value,
      prettyName: OTHERS[item].prettyName,
    })
  }

  return comercialArray
}

export const getAllCategoriesAsArray = () =>
  getApartmentAsArray()
    .concat(getHouseAsArray())
    .concat(getComercialAsArray())
    .concat(getOthersAsArray())
