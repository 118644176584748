import React from 'react'

const MarketReport = ({ width = 40, height = 40 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1122.000000, -1176.000000)"
        fill="#B28B3D"
        fillRule="nonzero"
      >
        <g transform="translate(1122.000000, 1176.000000)">
          <g>
            <path d="M12.512436,5.92819032 C12.512436,6.29167939 12.8067772,6.58671521 13.1709608,6.58671521 L36.2202581,6.58671521 C37.3094905,6.58671521 38.1959099,7.47313462 38.1959099,8.56236705 L38.1959099,35.0342485 C38.1959099,35.3977375 38.4902512,35.6927734 38.8544348,35.6927734 C39.2186185,35.6927734 39.5129597,35.3977375 39.5129597,35.0342485 L39.5129597,8.56236705 C39.5129597,6.74677387 38.0358513,5.26958825 36.2201809,5.26958825 L13.171038,5.26958825 C12.8068544,5.26966543 12.512436,5.56470125 12.512436,5.92819032 Z" />
            <path d="M39.0492972,38.1971447 L11.8539111,38.1971447 L11.8539111,8.56236705 C11.8539111,6.74677387 10.3768026,5.26958825 8.56113227,5.26958825 C6.74553909,5.26958825 5.26835347,6.7466967 5.26835347,8.56236705 L5.26835347,30.9530622 C5.26835347,32.0422947 4.38193405,32.9287141 3.29270162,32.9287141 C2.2034692,32.9287141 1.31704978,32.0422947 1.31704978,30.9530622 L1.31704978,1.31828456 L27.6591258,1.31828456 L27.6591258,3.29393641 C27.6591258,3.65742548 27.9534671,3.9524613 28.3176507,3.9524613 C28.6818343,3.9524613 28.9761756,3.65742548 28.9761756,3.29393641 L28.9761756,0.659759673 C28.9761756,0.296270603 28.6818343,0.0012347824 28.3176507,0.0012347824 L0.65852489,0.0012347824 C0.294341255,0.0012347824 0,0.296270603 0,0.659759673 L0,30.9530622 C0,32.7686554 1.47710845,34.245841 3.2927788,34.245841 C5.10837197,34.245841 6.5855576,32.7687326 6.5855576,30.9530622 L6.5855576,8.56236705 C6.5855576,7.47313462 7.47197701,6.58671521 8.56120944,6.58671521 C9.65044187,6.58671521 10.5368613,7.47313462 10.5368613,8.56236705 L10.5368613,32.9287141 L7.24408249,32.9287141 C6.87989885,32.9287141 6.5855576,33.2237499 6.5855576,33.587239 C6.5855576,33.950728 6.87989885,34.2457639 7.24408249,34.2457639 L10.5368613,34.2457639 L10.5368613,38.8555924 C10.5368613,39.2190815 10.8312025,39.5141173 11.1953862,39.5141173 L39.0492972,39.5141173 C39.4134809,39.5141173 39.7078221,39.2190815 39.7078221,38.8555924 C39.7078221,38.4921034 39.4134809,38.1971447 39.0492972,38.1971447 Z" />
            <path d="M31.6104295,29.7171987 L31.6104295,24.0628985 L31.8033643,24.2558333 C31.9317816,24.3842507 32.1004066,24.448768 32.2689544,24.448768 C32.4375794,24.448768 32.6061272,24.3842507 32.7345445,24.2558333 C32.9919967,23.998304 32.9919967,23.5821051 32.7345445,23.324653 L31.4181121,22.0082206 L31.4168002,22.0069087 L25.4905392,16.0805705 C25.2330871,15.8231184 24.8168111,15.8231184 24.5593589,16.0805705 L18.633098,22.0069087 L18.631786,22.0082206 L17.3153536,23.324653 C17.0578243,23.5821823 17.0578243,23.9983812 17.3153536,24.2558333 C17.5728829,24.5132854 17.9890818,24.5132854 18.2465339,24.2558333 L18.4394687,24.0628985 L18.4394687,29.7171987 C18.4394687,30.0806878 18.7338099,30.3757236 19.0979936,30.3757236 L23.7078221,30.3757236 C24.0720058,30.3757236 24.366347,30.0806878 24.366347,29.7171987 L24.366347,26.4244199 L25.683474,26.4244199 L25.683474,29.7171987 C25.683474,30.0806878 25.9778152,30.3757236 26.3419989,30.3757236 L30.9518274,30.3757236 C31.3160883,30.3757236 31.6104295,30.0806878 31.6104295,29.7171987 Z M30.2933797,29.0585966 L27.0005238,29.0585966 L27.0005238,25.7658178 C27.0005238,25.4023288 26.7061825,25.1072929 26.3419989,25.1072929 L23.7078221,25.1072929 C23.3436385,25.1072929 23.0492972,25.4023288 23.0492972,25.7658178 L23.0492972,29.0585966 L19.7565184,29.0585966 L19.7565184,22.7456944 L25.0249491,17.4772638 L30.2933797,22.7456944 L30.2933797,29.0585966 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default MarketReport
