export default () => {
  const formatData = (
    {
      created_at,
      sub_category_id,
      sub_category_name,
      neighborhood_name,
      neighborhood_id,
      city_id,
      city_name,
      city_uf,
      processed_address_formatted,
      processed_address_zip_code,
      processed_address_building_name,
      transaction_rent,
      transaction_season,
      transaction_sale,
      features_bedroom,
      features_garage,
      total_area,
      area,
      broker_id,
      broker_name,
      broker_raw,
      link,
      amenities,
      is_private,
      geo_process_pinned,
      zoning_id,
      zoning_name,
      location,
      meter_price,
      useful_meter_price,
    },
    id,
    sort,
    businessType,
  ) => {
    const price = Number(
      businessType === 'Temporada' ||
        businessType === 3 ||
        businessType === 'transaction_season'
        ? transaction_season
        : businessType === 'Locação' ||
          businessType === 2 ||
          businessType === 'transaction_rent'
        ? transaction_rent
        : transaction_sale,
    )
    return {
      Id: id,
      AdDate: created_at,
      Address: processed_address_formatted || '',
      Bedrooms: features_bedroom >= 0 ? features_bedroom : '',
      Building: processed_address_building_name || '',
      Category: sub_category_name || '',
      CategoryId: sub_category_id || '',
      Cep: processed_address_zip_code || '',
      City: city_name || '',
      CityId: city_id || '',
      Uf: city_uf || '',
      Garages: features_garage >= 0 ? features_garage : '',
      Link: link || '',
      MeterPrice:
        meter_price ||
        (price &&
          total_area &&
          (price / total_area).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })) ||
        '',
      Neighborhood: neighborhood_name || '',
      NeighborhoodId: neighborhood_id || '',
      Price:
        price.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || '',
      RealState: broker_name || broker_raw || '',
      RealStateId: broker_id || '',
      TotalArea: total_area || '',
      UsefulArea: area || '',
      UsefulMeterPrice:
        useful_meter_price ||
        (price &&
          area &&
          (price / area).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })) ||
        '',
      Amenities: amenities || '',
      IsPrivate: is_private || '',
      Mapped: geo_process_pinned || '',
      ZoningId: zoning_id || '',
      Zoning: zoning_name || '',
      BusinessType: businessType || '',
      location,
      sort,
    }
  }
  const formatRealties = ({ hits, businessType }) =>
    hits.map(item =>
      formatData(
        item._source || item,
        item._id || item.link_key || item.id,
        item.sort,
        businessType,
      ),
    )

  // eslint-disable-next-line no-restricted-globals
  self.addEventListener('message', event => {
    if (!event || !event.data) return
    const { data } = event
    const result = formatRealties(data)
    postMessage(result)
  })
}
