import axios from 'axios'
import { headers, logout } from 'services/auth'
import { callRequest } from 'services/requestHandler'

const API_URL = process.env.REACT_APP_API_URL
const UPDATE_USER_URL = `${API_URL}/conta/atualizar`
const GET_USER_INFO = `${API_URL}/conta/buscausuario?id=`
const UPDATE_PASSWORD = `${API_URL}/auth/redefinirsenha?`
const CONTRACT_DOWNLOAD = `${API_URL}/upload/download_contrato`
const SHOW_WIZARD = `${API_URL}/conta/wizardativo?id=`
const RESET_WIZARD = `${API_URL}/conta/resetarwizard?id=`
const REPORT_BUG = `${API_URL}/contato/enviarreport`
const PAYMENT_STATUS = `${API_URL}/Account/PaymentStatus`

export const wizardStatus = userId =>
  axios.get(`${SHOW_WIZARD}${userId}`, { headers })

export const resetWizard = userId =>
  axios.post(`${RESET_WIZARD}${userId}`, {}, { headers })

export const getUserInfo = userId =>
  axios.get(`${GET_USER_INFO}${userId}`, { headers })

export const reportBug = ({ id, error1, error2, error3, error4, email }) =>
  axios.post(
    REPORT_BUG,
    {
      ImovelId: id,
      Erro1: error1,
      Erro2: error2,
      Erro3: error3,
      Erro4: error4,
      Email: email,
    },
    { headers },
  )

export const updatePassword = ({
  email,
  currentPassword,
  newPassword,
  userId,
}) =>
  axios.post(
    `${UPDATE_PASSWORD}login=${email}&senhaAtual=${btoa(
      currentPassword,
    )}&senhaNova=${btoa(newPassword)}`,
    {},
    { headers },
  )

export const updateUserInfo = data =>
  axios.post(UPDATE_USER_URL, data, {
    headers: { ...headers },
  })

export const downloadContract = data =>
  axios.post(CONTRACT_DOWNLOAD, data, { headers, responseType: 'blob' })

export const getPaymentStatus = () =>
  callRequest(
    () => axios.get(PAYMENT_STATUS, { headers }),
    data => {
      if (data === 2) {
        logout()
        window.location = '/'
      }
    },
    null,
    err => {
      if (err.response.status === 401) {
        logout()
      }
    },
  )
