import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const REQUEST_DEMO = `${API_URL}/contato/enviar?`

export const requestDemo = ({ email, name, telephone }) => {
  const message = `<br/> <br/> Email:${email}<br/> Nome:${name}<br/> Telefone:${telephone}<br/>`
  const urlParam = `assunto=Agendamento&nome=${name}&email=contato@eemovel.com.br&mensagem=${message}`
  const payload = {
    assunto: 'Agendamento',
    email: 'contato@eemovel.com.br',
    mensagem: message,
    nome: name,
  }

  return axios.post(`${REQUEST_DEMO}${urlParam}`, payload)
}
