import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Switch, Route, Redirect } from 'react-router'
import I18n from 'locales'
import Search from 'pages/Search'
import Login from 'pages/Login'
import Report from 'pages/Report'
import OurTools from 'pages/OurTools'
import PrivateRoute from 'components/PrivateRoute'
import { verifyValidity } from 'services/auth'
import './index.css'

class App extends Component {
  componentDidMount() {
    verifyValidity()
    setInterval(verifyValidity, 900000)
  }

  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <main className="App">
            <Switch>
              <Route exact path="/" component={OurTools} />
              <Route exact path={I18n.t('routes.login')} component={Login} />
              <PrivateRoute
                exact
                path={I18n.t('routes.search')}
                component={Search}
              />
              <PrivateRoute
                exact
                path={I18n.t('routes.rgi')}
                component={Report}
              />
              <Redirect to="/" />
            </Switch>
          </main>
        </BrowserRouter>
      </React.Fragment>
    )
  }
}

export default App
