import React, { Component } from 'react'
import Header from 'components/Header'
import MobileHeader from 'components/MobileHeader'
import Footer from 'components/Footer'
import SmartSearch from 'components/SvgIcons/SmartSearch'
import RealtyAvaluation from 'components/SvgIcons/RealtyAvaluation'
import MarketReport from 'components/SvgIcons/MarketReport'
import Realty from 'components/SvgIcons/Realty'
import Map from 'components/SvgIcons/Map'
import ArrowRight from 'components/SvgIcons/ArrowRight'
import * as Yup from 'yup'
import I18n from 'locales'
import Users from 'components/SvgIcons/Users'
import { TELEPHONE_VALIDATION, GET_PHONE_MASK } from 'utils/mask'
import { Button } from 'components/Buttons'
import { Grid, Row, Col } from 'components/Grid'
import { Form, Field, Formik, InputMask } from 'components/Form'
import Odometer from 'react-odometerjs'
import { authed } from 'services/auth'
import { requestDemo } from 'services/ourTools'
import { getPaymentStatus } from 'services/user'
import { callRequest } from 'services/requestHandler'
import businessMan from '../../assets/images/business-man.png'
import './index.css'
import './odometer.css'

const DemonstrationSchema = Yup.object().shape({
  name: Yup.string().required(I18n.t('login.required')),
  email: Yup.string()
    .email(I18n.t('login.invalidEmail'))
    .required(I18n.t('login.required')),
  telephone: Yup.string()
    .matches(TELEPHONE_VALIDATION, 'Telefone Inválido')
    .required(I18n.t('login.required')),
})

class OurTools extends Component {
  constructor() {
    super()
    this.state = {
      currentComment: 1,
      realtyOdometer: 0,
      clientsOdometer: 0,
      citiesOdometer: 0,
      called: false,
    }
    this.isAtTop = window.scrollY < 3
  }

  componentDidMount() {
    document.addEventListener('scroll', this.verifyAtTop)
    authed() && getPaymentStatus()
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.verifyAtTop, false)
  }

  verifyAtTop = () => {
    this.isAtTop = window.scrollY < 3
    this.forceUpdate()
  }

  componentDidUpdate() {
    if (this.isInViewport() && !this.state.called) {
      this.startOdometer()
    }
  }

  startOdometer = () => {
    this.setState({
      realtyOdometer: 60000000,
      clientsOdometer: 2000,
      citiesOdometer: 180,
      called: true,
    })
  }

  nextComment = () => {
    const { currentComment } = this.state
    const nextComment = currentComment === 5 ? 1 : currentComment + 1
    this.setState({ currentComment: nextComment })
  }

  previousComment = () => {
    const { currentComment } = this.state
    const previousComment = currentComment === 1 ? 5 : currentComment - 1
    this.setState({ currentComment: previousComment })
  }

  isInViewport(offset = 0) {
    if (!this.odometer) return false
    const top = this.odometer.getBoundingClientRect().top
    return top + offset >= 0 && top - offset <= window.innerHeight
  }

  requestDemo = values => {
    callRequest(
      () => requestDemo(values),
      () =>
        window.M.toast({
          html: 'Demonstração solicitada com sucesso',
          classes: 'Toast--success',
        }),
    )
  }

  getComents = index => {
    if (index === 1) {
      return (
        <React.Fragment>
          <h2 className="OurTools__CommentText">
            A EEmovel tem me ajudado bastante! Quando um cliente procura um
            apartamento em alguma área específica da cidade, consigo rapidamente
            levantar imóveis para apresentar.
            <strong> Agiliza muito meu trabalho.</strong>
          </h2>
          <p className="OurTools__CommentBy">
            Jackson Souza - Corretor em Balneario Camboriú - Renato Imóveis
            Previous
          </p>
        </React.Fragment>
      )
    } else if (index === 2) {
      return (
        <React.Fragment>
          <h2 className="OurTools__CommentText">
            Agora as <strong>avaliações são muito mais rápidas</strong>, porque
            consigo encontrar muitas informações em um só lugar.
          </h2>
          <p className="OurTools__CommentBy">Elso Thomann, Cascavel - Paraná</p>
        </React.Fragment>
      )
    } else if (index === 3) {
      return (
        <React.Fragment>
          <h2 className="OurTools__CommentText">
            <strong>A EEmovel é a ferramenta ideal para o avaliador</strong>,
            pois na hora da pesquisa ela aglutina as amostras em um só lugar,
            facilitando assim nosso trabalho.
          </h2>
          <p className="OurTools__CommentBy">
            Paulo Roberto - Perito Avaliador em Curitiba - Avalie Já Avaliação e
            Perícia Imobiliária
          </p>
        </React.Fragment>
      )
    } else if (index === 4) {
      return (
        <React.Fragment>
          <h2 className="OurTools__CommentText">
            Consegui fechar uma parceria para a locação de um apartamento no
            condomínio Aquarius na Freguesia através da EEmovel.{' '}
            <strong>
              O site para nós corretores é muito útil, e fácil de utilizar
            </strong>
          </h2>
          <p className="OurTools__CommentBy">
            Poliana Cunha - Corretora no Rio de Janeiro - Poliana Cunha Imóveis
          </p>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <h2 className="OurTools__CommentText">
            <strong>
              Em menos de 1 semana fechei uma venda usando a EEmovel
            </strong>
            . Encontrei o apartamento ideal para o cliente, consegui captar e
            fechei o negócio! Com essa primeira comissão, a ferramenta já se
            pagou por mais de anos!!!
          </h2>
          <p className="OurTools__CommentBy">
            Luiz Gustavo Marchiotto, Itajaí - Santa Catarina
          </p>
        </React.Fragment>
      )
    }
  }

  render() {
    const {
      currentComment,
      realtyOdometer,
      clientsOdometer,
      citiesOdometer,
    } = this.state
    return (
      <div className="OurTools__Container">
        <div className="OurTools__HeaderDesktop">
          <Header home transparent={this.isAtTop} />
        </div>
        <div className="OurTools__HeaderMobile">
          <MobileHeader home transparent={this.isAtTop} />
        </div>
        <div className="OurTools">
          <h1 className="OurTools__Header">
            {I18n.t('ourTools.realStateIntelligence')}
          </h1>
          <p className="OurTools__Paragraph">
            {I18n.t('ourTools.makeMoreBusinnes')}
          </p>
          <div className="OurTools__BtnWrapper">
            <Button
              btnStyle="primary"
              onClick={() =>
                authed()
                  ? this.props.history.push(I18n.t('routes.search'))
                  : this.props.history.push(I18n.t('routes.login'))
              }
            >
              {I18n.t('ourTools.startHere')}
            </Button>
          </div>
          <span className="OurTools__Tip" />
        </div>
        <Grid>
          <Row center="xs" className="OurTools__Descriptions">
            <Col md={4} xs={12}>
              <h2 ref={el => (this.odometer = el)}>
                <Realty />+
                <Odometer value={realtyOdometer} format="(.ddd),dd" />{' '}
                {I18n.t('ourTools.ad')}
              </h2>
            </Col>
            <Col md={4} xs={12}>
              <h2>
                <Users />
                +<Odometer value={clientsOdometer} format="(.ddd),dd" />{' '}
                {I18n.t('ourTools.clients')}
              </h2>
            </Col>
            <Col md={4} xs={12}>
              <h2>
                <Map />
                +<Odometer value={citiesOdometer} format="(.ddd),dd" />{' '}
                {I18n.t('ourTools.cities')}
              </h2>
            </Col>
          </Row>
          <Row center="xs" className="OurTools__Descriptions">
            <Col xs={12}>
              <Row>
                <Col xs={12} className="OurTools__MiniHeader">
                  <h2>{I18n.t('ourTools.functionalities')}</h2>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p className="OurTools__Text">
                    {I18n.t('ourTools.weCreated')}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row center="xs" className="OurTools__Descriptions">
            <Col md={4} xs={12}>
              <Row>
                <Col xs={12} className="OurTools__MiniHeader">
                  <SmartSearch />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="OurTools__MiniHeader">
                  <h2>{I18n.t('register.smartSearch')}</h2>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p className="OurTools__Text">{I18n.t('ourTools.findAll')}</p>
                </Col>
              </Row>
            </Col>
            <Col md={4} xs={12}>
              <Row>
                <Col xs={12} className="OurTools__MiniHeader">
                  <RealtyAvaluation />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="OurTools__MiniHeader">
                  <h2>{I18n.t('register.realtyRating')}</h2>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p className="OurTools__Text">
                    {I18n.t('ourTools.doAvaluations')}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={4} xs={12}>
              <Row>
                <Col xs={12} className="OurTools__MiniHeader">
                  <MarketReport />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="OurTools__MiniHeader">
                  <h2>{I18n.t('register.marketReport')}</h2>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p className="OurTools__Text">
                    {I18n.t('ourTools.understandTheMarket')}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
        <div className="OurTools__CommentContainer">
          <div
            className="OurTools__CommentArrow"
            onClick={this.previousComment}
          >
            <i className="material-icons">keyboard_arrow_left</i>
          </div>
          <div className="OurTools__Comment">
            {this.getComents(currentComment)}
          </div>
          <div className="OurTools__CommentArrow" onClick={this.nextComment}>
            <i className="material-icons">keyboard_arrow_right</i>
          </div>
        </div>
        <div className="OurTools__TalkWithUs">
          <Grid>
            <h2>{I18n.t('ourTools.askForADemo')}</h2>
            <Formik
              initialValues={{ name: '', email: '', telephone: '' }}
              validationSchema={DemonstrationSchema}
              onSubmit={this.requestDemo}
            >
              {({ errors, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12}>
                      <Field
                        name="name"
                        placeholder="Digite seu nome"
                        error={errors.name}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Field
                        name="email"
                        placeholder="Digite seu e-mail"
                        error={errors.email}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <InputMask
                        mask={GET_PHONE_MASK}
                        name="telephone"
                        onChange={handleChange}
                        placeholder="Digite seu Telefone"
                        error={errors.telephone}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Button
                        type="submit"
                        btnStyle="primary"
                        btnSize="lg"
                        block
                      >
                        {I18n.t('ourTools.doAskForADemo')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Grid>
        </div>
        <Grid className="OurTools__VideoNews OurTools__Grid">
          <Row>
            <Col md={6} xs={12}>
              <Row>
                <Col xs={12}>
                  <h1>{I18n.t('ourTools.videos')}</h1>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <iframe
                    title="O que é necessário para ser o melhor corretor de 2019?"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/NBlncB_BwNA"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <h3>{I18n.t('ourTools.whatItTakes')}</h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <a
                    className="OurTools__OurChannel"
                    href="https://www.youtube.com/channel/UCP_YYG2TqBjEIVZm5TsBjFA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {I18n.t('ourTools.ourChannel')} <ArrowRight />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col md={6} xs={12}>
              <Row>
                <Col xs={12}>
                  <h1>{I18n.t('ourTools.lastNews')}</h1>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col md={6} xs={12}>
                      <Row>
                        <Col xs={12}>
                          <img
                            alt="EEmovel no inovativa"
                            className="OurTools__Img"
                            src="https://www.eemovel.com.br/assets/images/innova.jpg"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <h3>EEmovel finalista Inovativa Brasil 2016</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <p className="OurTools__NewsText">
                            Startup foi destaque no programa, com investidores,
                            empreendedores e grandes empresas.
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} xs={12}>
                      <Row>
                        <Col xs={12}>
                          <p className="OurTools__NewsText">30 set 2016</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://www.startupsstars.com/2016/09/eemovel-startup-da-vez/"
                            className="OurTools__NewsLink"
                          >
                            Startup da vez
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <p className="OurTools__NewsText">6 mar 2015</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.youtube.com/watch?v=SeFmpGhRHPg"
                            className="OurTools__NewsLink"
                          >
                            Entrevista com Luiz Felipe Cervi, CEO-EEmovel
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
        <Grid className="OurTools__Grid OurTools__Help">
          <Row middle="xs">
            <Col md={8} xs={12}>
              <Row>
                <Col xs={12}>
                  <h1>{I18n.t('ourTools.howCanIHelp')}</h1>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p>{I18n.t('ourTools.askYourQuestions')}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button
                    btnSize="lg"
                    btnStyle="flat"
                    onClick={() =>
                      window.open('https://wa.me/554598323749', '_tab')
                    }
                  >
                    {I18n.t('ourTools.talkToUs')}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={4} xs={12}>
              <img
                src={businessMan}
                className="OurTools__BusinessMan"
                alt={I18n.t('ourTools.businessManAlt')}
              />
            </Col>
          </Row>
        </Grid>
        <Footer />
      </div>
    )
  }
}

export default OurTools
