import React, { Component } from 'react'
import { Field, Form } from 'components/Form'
import { Button } from 'components/Buttons'
import { Formik } from 'formik'
import { Row, Col } from 'components/Grid'
import { ClipLoader } from 'react-spinners'
import Logo from 'components/MinLogo'
import I18n from 'locales'
import { verifyImpersonate } from 'services/admin'
import { callRequest } from 'services/requestHandler'
import * as Yup from 'yup'
import * as auth from 'services/auth'
import './index.css'
import classNames from 'classnames'

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(I18n.t('login.invalidEmail'))
    .required(I18n.t('login.required')),
  password: Yup.string()
    .min(3, I18n.t('login.tooShort'))
    .required(I18n.t('login.required')),
})

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(I18n.t('login.invalidEmail'))
    .required(I18n.t('login.required')),
})

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
      loginError: this.getLoginErrorMessage(),
      forgotPassword: false,
      loading: false,
      isImpersonate: false,
    }
  }

  componentDidMount() {
    if (auth.authed()) {
      callRequest(verifyImpersonate, data =>
        this.setState({ isImpersonate: data }),
      )
    }
  }

  getLoginErrorMessage = () => {
    const { reason } = this.props.match.params
    switch (reason) {
      case 'sessionexpired':
        return 'Sessão Expirada'
      case 'validityexpired':
        return 'Vigência expirada'
      default:
        return null
    }
  }

  doLogin = ({ email, password, impersonate = null }) => {
    this.setState({ loading: true })
    auth
      .login(email, password, impersonate)
      .then(({ data, status, statusText }) => {
        if (status === 200) {
          auth.setToken(data.access_token)
          auth
            .getUserInfo(data.access_token)
            .then(({ data }) => {
              if (
                data.Roles.find(
                  item =>
                    item.toLowerCase() === 'staff' ||
                    item.toLowerCase() === 'dataintelligence' ||
                    item.toLowerCase() === 'admin',
                )
              ) {
                auth.storeUser(data)
                setTimeout(
                  () => (window.location = I18n.t('routes.search')),
                  500,
                )
              } else {
                auth.logout()
                this.setState({
                  loginError: 'Usuário inválido',
                  loading: false,
                })
              }
            })
            .catch(err => {
              console.error(err)
              this.setState({ loading: false })
            })
        } else {
          this.setState({ loginError: statusText, loading: false })
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          loginError:
            err.response.data.error === 'invalid_grant'
              ? 'Usuário ou senha inválido'
              : '',
        })
      })
  }

  toggleVisibility = ({ setFieldValue, values }) => {
    this.setState(state => ({ showPassword: !state.showPassword }))
  }

  render() {
    const {
      showPassword,
      loginError,
      forgotPassword,
      loading,
      isImpersonate,
    } = this.state
    const icon = showPassword ? 'visibility' : 'visibility_off'
    const modalClasses = classNames({
      'Login__Pane--hidden': !forgotPassword,
      Login__Pane: forgotPassword,
    })
    return (
      <div className="Login">
        <div className="Login__Container">
          <div className="Login__LogoContainer">
            <Logo />
          </div>
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={this.doLogin}
            validationSchema={LoginSchema}
          >
            {props => (
              <Form onSubmit={props.handleSubmit} className="Login__Form">
                <Row>
                  <Col xs={12}>
                    <Field
                      label=""
                      placeholder={I18n.t('login.email')}
                      name="email"
                      type="email"
                      values={props.values.email}
                      onChange={props.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="Login__ErrorCol">
                    {props.errors.email && (
                      <span className="Login__Error">{props.errors.email}</span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="Login__PasswordRow">
                    <input
                      className="Login__Input"
                      placeholder={I18n.t('login.password')}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      values={props.values.password}
                      onChange={props.handleChange}
                    />
                    <button type="button" className="Login__Visibility">
                      <i
                        className="material-icons"
                        onClick={() => this.toggleVisibility(props)}
                      >
                        {icon}
                      </i>
                    </button>
                  </Col>
                </Row>
                {isImpersonate && (
                  <Row>
                    <Col xs={12} className="Login__PasswordRow">
                      <Field
                        label=""
                        placeholder="E-mail impersonate"
                        name="impersonate"
                        type="impersonate"
                        values={props.values.impersonate}
                        onChange={props.handleChange}
                      />
                    </Col>
                  </Row>
                )}
                <Row start="xs">
                  <Col xs={12} className="Login__ErrorCol">
                    {props.errors.password && (
                      <span className="Login__Error">
                        {props.errors.password}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Button
                      type="submit"
                      className="Login__Btn"
                      btnStyle="primary"
                      btnSize="lg"
                      disabled={loading}
                      block
                    >
                      {!loading ? (
                        I18n.t('login.login')
                      ) : (
                        <React.Fragment>
                          <div className="Login__LoadingContainer">
                            <div className="Login__Spinner">
                              <ClipLoader
                                sizeUnit="px"
                                size={16}
                                color="#FFFFFF"
                                margin="5px"
                                loading
                              />
                            </div>
                            Aguarde...
                          </div>
                        </React.Fragment>
                      )}
                    </Button>
                  </Col>
                </Row>
                <Row start="xs">
                  <Col xs={12} className="Login__ErrorCol">
                    {loginError && (
                      <span className="Login__Error">{loginError}</span>
                    )}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
        <div className={modalClasses}>
          <div className="Login__Modal">
            <div className="Login__Header">
              <Row end="xs">
                <Col xs={12}>
                  <i
                    className="material-icons"
                    onClick={() => this.setState({ forgotPassword: false })}
                  >
                    close
                  </i>
                </Col>
              </Row>
            </div>
            <div className="Login__Body">
              <Row>
                <Col xs={12}>
                  <h2>Esqueceu sua senha?</h2>
                  <h4>
                    Informe seu e-mail e nós enviaremos uma nova senha para
                    você!
                  </h4>
                </Col>
              </Row>
              <Formik
                initialValues={{ email: '' }}
                validationSchema={ForgotPasswordSchema}
                onSubmit={auth.forgotPassword}
              >
                {({
                  values: { email },
                  errors,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <Field
                          name="email"
                          value={email}
                          error={errors.email}
                          onChange={handleChange}
                          placeholder={I18n.t('login.email')}
                        />
                      </Col>
                    </Row>
                    <Row end="xs">
                      <Col xs={12}>
                        <Button type="submit" btnStyle="primary">
                          Reenviar Senha
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginPage
