import React from 'react'

const SmartSearch = ({ width = 40, height = 40 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-282.000000, -1176.000000)"
        fill="#B28B3D"
        fillRule="nonzero"
      >
        <g transform="translate(282.000000, 1176.000000)">
          <g>
            <path d="M30.1218713,26.4110937 C29.8623782,26.6710937 29.8623782,27.09375 30.1218713,27.35375 L31.5338012,28.7684375 C31.6635478,28.8991406 31.8339181,28.96375 32.0042105,28.96375 C32.1738791,28.96375 32.3442495,28.8990625 32.4746199,28.7684375 C32.7341131,28.5084375 32.7341131,28.0864062 32.4746199,27.8257812 L31.0633918,26.4110937 C30.8031969,26.1510937 30.3819883,26.1510937 30.1218713,26.4110937 Z" />
            <path d="M37.2605848,14.94375 L37.2605848,21.3344531 L34.5991423,21.3344531 L34.5991423,18.0010937 C34.5991423,17.633125 34.3017544,17.3344531 33.9338012,17.3344531 C33.565848,17.3344531 33.26846,17.633125 33.26846,18.0010937 L33.26846,22.0010937 C33.26846,22.3690625 33.565848,22.6677344 33.9338012,22.6677344 L37.9260039,22.6677344 C38.2939571,22.6677344 38.591345,22.3690625 38.591345,22.0010937 L38.591345,16.2771094 L38.7862768,16.4724219 C38.9160234,16.6024219 39.0863938,16.6677344 39.2566862,16.6677344 C39.4270565,16.6677344 39.5973489,16.6024219 39.7270955,16.4724219 C39.9872904,16.2117187 39.9872904,15.7903906 39.7270955,15.5297656 L38.397037,14.1971094 C38.397037,14.1971094 38.3963353,14.1964062 38.3957115,14.1957812 L32.4081092,8.19648438 C32.1479142,7.93578125 31.7274074,7.93578125 31.4672904,8.19648438 C31.2070955,8.45710938 31.2070955,8.87851563 31.4672904,9.13914063 L37.2605848,14.94375 Z" />
            <path d="M22.4282261,14.1971094 C22.4282261,14.1971094 22.4276023,14.1964062 22.4269006,14.1957812 L16.4392203,8.19648437 C16.1790253,7.93578125 15.7585185,7.93578125 15.4984016,8.19648437 L9.51072125,14.1957812 C9.51072125,14.1957812 9.51001949,14.1964844 9.50939571,14.1971094 L8.17933723,15.5297656 C7.9191423,15.7904687 7.9191423,16.2117969 8.17933723,16.4724219 C8.43953216,16.7330469 8.86003899,16.7330469 9.12015595,16.4724219 L9.31508772,16.2771094 L9.31508772,22.0010937 C9.31508772,22.3690625 9.61247563,22.6677344 9.98042885,22.6677344 L14.6379727,22.6677344 C15.0059259,22.6677344 15.3033138,22.3690625 15.3033138,22.0010937 L15.3033138,18.6677344 L16.6340741,18.6677344 L16.6340741,22.0010937 C16.6340741,22.3690625 16.931462,22.6677344 17.2994152,22.6677344 L21.957115,22.6677344 C22.3250682,22.6677344 22.6224561,22.3690625 22.6224561,22.0010937 L22.6224561,16.2771094 L22.8173879,16.4724219 C22.9471345,16.6024219 23.1175049,16.6677344 23.2877973,16.6677344 C23.4581676,16.6677344 23.62846,16.6024219 23.7582066,16.4724219 C24.0183236,16.2117187 24.0183236,15.7903906 23.7582066,15.5297656 L22.4282261,14.1971094 Z M21.2918519,21.3344531 L17.9649123,21.3344531 L17.9649123,18.0010937 C17.9649123,17.633125 17.6675244,17.3344531 17.2995712,17.3344531 L14.6381287,17.3344531 C14.2701754,17.3344531 13.9727875,17.633125 13.9727875,18.0010937 L13.9727875,21.3344531 L10.6459259,21.3344531 L10.6459259,14.94375 L15.9688889,9.61039062 L21.2918519,14.94375 L21.2918519,21.3344531 Z" />
            <path d="M15.9688109,2.66773437 C8.63111111,2.66773437 2.6614425,8.6490625 2.6614425,16.0010937 C2.6614425,23.353125 8.63111111,29.3344531 15.9688109,29.3344531 C23.3065107,29.3344531 29.2761793,23.353125 29.2761793,16.0010937 C29.2761793,8.6490625 23.3065107,2.66773437 15.9688109,2.66773437 Z M15.9688109,28.5990527 C9.03600429,28.5990527 3.39540936,22.947441 3.39540936,16.0010937 C3.39540936,9.05474648 9.03600429,3.40313477 15.9688109,3.40313477 C22.9016175,3.40313477 28.5422125,9.05474648 28.5422125,16.0010937 C28.5422125,22.947441 22.9016175,28.5990527 15.9688109,28.5990527 Z" />
            <path d="M34.2843665,29.6390625 C34.159922,29.514375 33.9909552,29.44375 33.8139571,29.44375 L33.8133333,29.44375 C33.637037,29.444375 33.4673684,29.5150781 33.3422222,29.6403906 L31.4658869,31.5310937 C31.2063938,31.7917969 31.2077193,32.21375 31.468538,32.47375 C31.7293567,32.73375 32.1505653,32.733125 32.4093567,32.4710937 L33.8152827,31.0550781 L38.0063938,35.25375 C38.3830019,35.6317187 38.5912671,36.13375 38.5912671,36.6677344 C38.5912671,37.2017187 38.3830019,37.704375 38.0063938,38.0817187 C37.228616,38.8610156 35.9630409,38.8623437 35.1839376,38.0823437 L27.2993372,30.1823437 C27.0398441,29.9223437 26.6186355,29.9223437 26.3585185,30.1823437 C26.0990253,30.4430469 26.0990253,30.865 26.3585185,31.125 L34.2431189,39.025 C34.8911501,39.675 35.742846,39.9989844 36.5945419,39.9989844 C37.4462378,39.9989844 38.2991813,39.6742969 38.9472904,39.025 C39.5753606,38.395 39.9220273,37.5583594 39.9220273,36.6676562 C39.9220273,35.7776562 39.5753606,34.9403125 38.9472904,34.3110156 L34.2843665,29.6390625 Z" />
            <path d="M31.9376218,16.0010937 C31.9376218,7.1790625 24.7736452,0.00109375 15.9688109,0.00109375 C7.16397661,0.00109375 0,7.1790625 0,16.0010937 C0,24.823125 7.16397661,32.0010937 15.9688109,32.0010937 C24.7736452,32.0010937 31.9376218,24.823125 31.9376218,16.0010937 Z M15.9688109,30.6677344 C7.89723197,30.6677344 1.33076023,24.0884375 1.33076023,16.0010937 C1.33076023,7.91375 7.89723197,1.33445312 15.9688109,1.33445312 C24.0403899,1.33445312 30.6068616,7.91375 30.6068616,16.0010937 C30.6068616,24.0884375 24.0403899,30.6677344 15.9688109,30.6677344 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SmartSearch
