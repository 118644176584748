import axios from 'axios'
import bodybuilder from 'bodybuilder'
import { deburr } from 'lodash'
import * as auth from 'services/auth'

const API_URL = process.env.REACT_APP_API_URL
const DWELLERS_BY_LOCATION = '/moradores/buscamoradores'
const DWELLERS_BY_DOCUMENT = '/moradores/buscamorador'
const CEP_BY_ADDRESS = '/moradores/buscacepporendereco'
const USE_AMOUNT = '/moradores/verificaqtdrequisicoes'
const OWNERS_BY_LOCATION = '/moradores/_search'
const OWNERS_MATCH_DOCUMENT = '/moradores/buscaproprietarios'

export default class Dwellers {
  constructor({ host = API_URL, cacheResponses = true } = {}) {
    this.host = host
    this.headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'x-pessoaid': auth.userId() || 48,
      ...auth.headers,
    }
  }

  searchByLocation(options = {}) {
    const payload = {
      Rua: options.street,
      Cep: options.cep,
      cidade: options.city,
      uf: options.uf,
      numeroInicial: Number(options.numbers[0]),
      numeroFinal: Number(options.numbers[1]),
    }

    return axios.post(this.host + DWELLERS_BY_LOCATION, payload, {
      headers: this.headers,
    })
  }

  searchByDocument(doc) {
    const payload = {
      Documento: btoa(doc),
    }

    return axios.post(this.host + DWELLERS_BY_DOCUMENT, payload, {
      headers: this.headers,
    })
  }

  searchCepByAddress(query = {}) {
    const { address, uf, city } = query

    const payload = {
      searchText: address,
      estadoUf: uf,
      cidadeNome: city,
    }

    return axios.post(this.host + CEP_BY_ADDRESS, payload, {
      headers: this.headers,
    })
  }

  async checkUsesAmount() {
    return await axios.get(this.host + USE_AMOUNT, {
      headers: this.headers,
    })
  }

  searchOwners(options, coordinates) {
    let body = bodybuilder()

    const numbers = Array.from(
      new Array(options.numbers[1] - options.numbers[0] + 1),
      (val, index) => index + Number(options.numbers[0]),
    )

    body
      .size(10000)
      .orQuery('match', 'cep', options.cep)
      .query('match', 'logradouro', deburr(options.street))
      .query('match', 'cidade.Nome', options.city)
      .query('match', 'cidade.UF', options.uf)
      .filter('geo_distance', { distance: '250m', location: coordinates })
      .filter('terms', 'numero', numbers)
      .filterMinimumShouldMatch(1)
      .rawOption('_source', [
        'proprietario',
        'tipo_documento',
        'cidade',
        'endereco',
        'logradouro',
        'numero',
        'complemento',
        'numero_documento',
        'tipo_documento',
      ])

    return axios.post(
      this.host + OWNERS_BY_LOCATION,
      JSON.stringify(JSON.stringify(body.build())),
      { headers: this.headers },
    )
  }

  matchDocumentOwners(options) {
    const payload = {
      nome: options.Name,
      uf: options.addresses[0].uf,
      cidade: options.addresses[0].city,
      documento: options.document,
      tipodocumento: options.Type.includes('sica') ? 'CPF' : 'CNPJ',
    }

    return axios.post(this.host + OWNERS_MATCH_DOCUMENT, payload, {
      headers: this.headers,
    })
  }
}
